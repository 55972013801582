import RestUtilities, { IRestResponse } from "./RestUtilities";
import IAssignment from "../models/IAssignment"

export default class Assignments {
  static async fetchAllAsync(): Promise<IRestResponse<IAssignment[]>> {
    return await RestUtilities.get<IAssignment[]>("/api/consultant/Assignment");
  }

  static async fetchAllByConsultantAsync(consultant: string): Promise<IRestResponse<IAssignment[]>> {
    return await RestUtilities.get<IAssignment[]>(`/api/consultant/Assignment/${consultant}`);
  }

  static async fetchByConsultantAndTenantAsync(assignment: IAssignment): Promise<IRestResponse<IAssignment>> {
    return await RestUtilities.post<IAssignment>(`/api/consultant/Assignment/`, assignment);
  }

  static async updateAsync(username: string, assignments: IAssignment[]) {
    return await RestUtilities.post<IAssignment[]>(`/api/consultant/Assignment/${username}`, assignments);
  }
}