import * as React from 'react';
import { Grid, TextField, makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import IK8sJobStatus from '../models/IK8sJobStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: '100%'
    },
    textField: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    interactiveGroupSpacing: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    buttons: {
      marginLeft: theme.spacing(2)
    },
    spaced: {
      marginTop: theme.spacing(2)
    },
    wrapper: {
      margin: theme.spacing(2),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: '#6AA2DC', //Medium Green
      '&:hover': {
        backgroundColor: '#2F78C4', //Dark Green
      },
    },
    buttonProgress: {
      color: '#6AA2DC',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export interface TaskJobStatusGridProps {
    taskJobStatus: IK8sJobStatus | null;
}
export const TaskJobStatusGrid = (props: TaskJobStatusGridProps) => {
    const classes = useStyles();
    const { taskJobStatus}  = props;

    return (
        <>
            <Grid container direction={'row'} spacing={2}>
                <Grid item xs={10}>
                    <Box>
                        { taskJobStatus ?
                            <>
                                <Grid container item xs={12}>
                                    <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            size={'small'}
                                            label={'Pods Active'}
                                            value={taskJobStatus.active ?? ''}
                                            className={classes.textField}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            variant={'filled'} />
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            size={'small'}
                                            label={'Completed Time'}
                                            value={taskJobStatus.completionTime ?? ''}
                                            className={classes.textField}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            variant={'filled'} />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            size={'small'}
                                            label={'Failed Pods'}
                                            value={taskJobStatus.failed ?? ''}
                                            className={classes.textField}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            variant={'filled'} />
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            size={'small'}
                                            label={'Ready Pods'}
                                            value={taskJobStatus.ready ?? ''}
                                            className={classes.textField}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            variant={'filled'} />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            size={'small'}
                                            label={'Start Time'}
                                            value={taskJobStatus.startTime ?? ''}
                                            className={classes.textField}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            variant={'filled'} />
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <TextField
                                            fullWidth
                                            size={'small'}
                                            label={'Succeeded Pods'}
                                            value={taskJobStatus.succeeded ?? ''}
                                            className={classes.textField}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            variant={'filled'} />
                                    </Grid>
                                </Grid>
                            </>
                            :
                            "No Job Status" 
                        }
                    </Box>
                </Grid>
            </Grid>                     
        </>
    )
}
export default TaskJobStatusGrid;