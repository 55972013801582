import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import CloudIcon from "@material-ui/icons/Cloud";
import GroupIcon from "@material-ui/icons/Group";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import SchoolIcon from "@material-ui/icons/School";
import HistoryIcon from "@material-ui/icons/History";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import EcoIcon from "@material-ui/icons/Eco";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import { Switch, Route } from "react-router-dom";
import { LinearProgress, Menu, MenuItem } from "@material-ui/core";
import { useMsal, useAccount } from "@azure/msal-react";
import { getGraphUserProfile, getGraphPic } from "./GraphAuth";
import { AccountInfo } from '@azure/msal-common';
import * as signalR from '@microsoft/signalr';
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { useNavMenuStyles } from "../themes/NavMenuStyles";
import { IConsultant } from "../models/IConsultant";
import IAssignment from "../models/IAssignment";
import WdCredentialsDialog from '../components/WdCredentialsDialog';
import AvatarMenu from "../components/AvatarMenu";
import ListItemLink from "../components/ListItemLink"
// Context
import { ConsultantContext } from "../contexts/ConsultantContext";
import { AdminContext } from "../contexts/AdminContext";
import { UsernameContext } from "../contexts/UsernameContext";
import { SelectedTenantContext } from '../contexts/SelectedTenantContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { CustomThemeContext } from '../contexts/CustomThemeContext';
import { WdCredentialsContext } from '../contexts/WdCredentialsContext';
// Pages
import Tenants from "../pages/Tenants";
import Consultants from "../pages/Consultants";
import Home from "../pages/Home";
import logout from '../pages/Logout';
import Health from '../pages/Health';
import FunctionalArea from "../pages/FunctionalArea";
import AssignmentService from "../services/Assignments";
import ConsultantService from "../services/Consultants";
import { IRestResponse } from "../services/RestUtilities";
import { setApiAccessToken, getGraphAccessToken } from '../services/AuthService';

export const NavMenu = (): JSX.Element => {
  const classes = useNavMenuStyles({} as any);
  const theme = useTheme();
  const { instance, accounts, inProgress } = useMsal();
  const account: AccountInfo | null = useAccount(accounts[0]);
  const [tenantNames, setTenantNames] = useState<string[]>([]);
  const [photo, setPhoto] = useState<string>('');
  const [graphAccount, setGraphAccount] = useState<any>();
  const [ implementerId, setImplementerId ] = useState<string>('');
  const [ implementerPwd, setImplementerPwd ] = useState<string>('');
  // States for Context
  const { wdImplementerId, wdImplementerPwd } = useContext(WdCredentialsContext);
  const [consultant, setConsultant] = useState<IConsultant | null>(null);
  const [loading, setLoading] = useState(false);
  const [interfaceAdmin, setInterfaceAdmin] = useState(false);
  const [username, setUsername] = useState<string>("");

  // Tenant Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Set to 0, so the user is forced to pick a tenant.
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  // Menu & theming
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { currentTheme, setTheme } = useContext(CustomThemeContext);

  const [ hubConnection ] = useState<signalR.HubConnection>(new signalR.HubConnectionBuilder().withUrl("/taskregisterhub").configureLogging(signalR.LogLevel.Information).build());

  const setProfileData = async (): Promise<void> => {
    if (account) {
      // Get access for use with Graph
      let graphToken = await getGraphAccessToken(instance, account);

      // Get 'me' profile from Graph
      const graphResult = await getGraphUserProfile(graphToken);

      // Get photo from Graph
      const userPhoto: Blob = await getGraphPic(graphToken);
      const url = window.URL || webkitURL;
      const photoUrl: string = url.createObjectURL(userPhoto);
      setGraphAccount(graphResult);
      setPhoto(photoUrl);

      await setApiAccessToken(instance, account);

      // Get consultant.username from our api
      let response: IRestResponse<IConsultant> = await ConsultantService.fetchByUseremail(graphResult.mail);
      
      if (response.content === undefined) {
        setConsultant(null);
        return;
      }

      var currentConsultant = response.content;
      setUsername(currentConsultant?.username);
      if(currentConsultant.interfaceAdmin !== undefined)
      {
        setInterfaceAdmin(currentConsultant?.interfaceAdmin);
      }
      else{
        setInterfaceAdmin(false);
      }
      setConsultant(currentConsultant as IConsultant);
      getAssignments(currentConsultant?.username);
    }
  }
  
  useEffect(() => { // hubconnection 
    if (inProgress === "none" && account) {
      try 
      {
        startHubConnection();
        // Retrieve an access token
        setProfileData();
      } 
      catch (err) 
      {
        console.table(err);  
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, account, instance]);

  useEffect(() => { // updateSelectedTenant
    updateSelectedTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantNames]);

  const handleThemeChange = () => {
      setTheme(currentTheme === 'dark' ? "light" : "dark");
  };

  const getAssignments = async (_username: string): Promise<void> => {
    if (account) {
      try{
        // Gets a token for our web api, sets the token in the RestUtilities class
      await setApiAccessToken(instance, account);
      let response: IRestResponse<IAssignment[]> = await AssignmentService.fetchAllByConsultantAsync(_username);
      if (response.content?.length === 0) return;
      const tableDataCopy = [...response.content as IAssignment[]];

      // TODO: Get Tenant data from Tenants table to show CustomerName field
      let names: string[] = tableDataCopy.map(t => t.tenant);
      names.unshift("Choose a Tenant");
      setTenantNames(names);
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
    }
  };

  const handleTenantMenuItemClick = async (event: React.MouseEvent<HTMLElement>, index: number) => {
    setLoading(true);
    setSelectedIndex(index);
    setAnchorEl(null);
    const tenantName = tenantNames[index];

    try {
      localStorage.setItem('selectedTenant', tenantName);
      setSelectedTenant(tenantName);
    }
    catch (err) {
      toast('Could not find Tenant in local storage.');
      console.error(err);
    }
    finally{
      setLoading(false);
    }
  };

  const setImplementerLogin = async(tenantName: string) => {
    try {
      // call API for Assignments and fetch values
      await setApiAccessToken(instance, account!);
      let assignment: IAssignment = {
        consultant:  consultant?.username,
        tenant: tenantName, 
        implementerId: wdImplementerId,
        implementerPwd: wdImplementerPwd,
        refreshToken: "",
        useRefreshToken: false,
        wwsValidationOnly: true
      }
      const res = await toast.promise<IRestResponse<IAssignment>>(AssignmentService.fetchByConsultantAndTenantAsync(assignment), {
        success: 'Retrieving consultant implementer id, implementer pwd, and wwsvalidation status.',
        error: (err: AxiosError) => `Error: Could not retrieve consultant info.\nDetails: ${err?.response?.data ?? 'No details could be provided.'}`,
        loading: 'Loading consultant info...'
      });
      assignment = res.content as IAssignment;
      setImplementerId(assignment.implementerId);
      setImplementerPwd(assignment.implementerPwd);
    } catch (error) {
      const ax = error as AxiosError;
      console.table(ax.response?.data);
    }
  }

  const updateSelectedTenant = () => {
    const existingSelectedTenant: string | null = localStorage.getItem('selectedTenant');
    if(existingSelectedTenant){
      const index = tenantNames.findIndex(te => te === existingSelectedTenant);
      const tenantName = tenantNames[index];
      if (tenantName) {
        setSelectedTenant(tenantName);
        setSelectedIndex(index);
        setImplementerLogin(tenantName);
      }
    }
  }

  // Signal R hub connection started here and can stay connected. Group listeners invoked in TaskGrid.
   const startHubConnection = async (): Promise<void> => {
    // Transport fallback functionality is now built into start.
    await hubConnection.start().then(() => {
      console.log('Started Client Hub Connection');
    }).catch((error) => {
      // AxiosError is used here to get a better understanding of what the error is.
      const ax = error as AxiosError;
      console.table(ax.response?.data);
    });
  }

  return (
    <div className={classes.root}>
        <CssBaseline />
        <WdCredentialsContext.Provider value={{ wdImplementerId: implementerId, setWdImplementerId: setImplementerId, wdImplementerPwd: implementerPwd, setWdImplementerPwd: setImplementerPwd }}>
          <AppBar
            color="primary"
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: drawerOpen,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setDrawerOpen(true)}
                edge="start"
                className={clsx(classes.menuButton, { [classes.hide]: drawerOpen })}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Cognizant Daytona Tool
              </Typography>
              {
                loading &&
                <LinearProgress color="primary" style={{ width: '70%' }} />
              }
              <List component="nav" dense>
                <ListItem
                  button
                  disabled={tenantNames.length === 0}
                  onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
                >
                  <ListItemText primary="Current Tenant" secondary={selectedTenant} />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {tenantNames.map((name, index) => (
                  <MenuItem
                    key={index}
                    disabled={index === 0}
                    selected={index === selectedIndex}
                    onClick={(event) => handleTenantMenuItemClick(event, index)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Menu>
              <WdCredentialsDialog
                consultant={consultant?.username}
                tenant={selectedTenant}
                disabled={tenantNames.length === 0}
              />
              {/* User profile */}
              <AvatarMenu
                photoUrl={photo}
                email={graphAccount?.mail}
              />
              <IconButton onClick={handleThemeChange}>
                {currentTheme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={() => setDrawerOpen(false)}>
                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />
            <List aria-label="primary links">
              <ListItemLink
                to="/"
                primary="Home"
                disabled={false}
                icon={<HomeIcon />} />
              <ListItemLink
                to="/tenants"
                primary="Tenants"
                disabled={consultant === null}
                icon={<CloudIcon />}
              />
              <ListItemLink
                to="/consultants"
                primary="Customer Conversion"
                disabled={consultant === null}
                icon={<GroupIcon />}
              />
            </List>
            <Divider />
            <List aria-label="secondary links">
              <ListItemLink
                to="/hcm"
                primary="Human Capital"
                disabled={selectedTenant === ""}
                icon={<AccountBalanceIcon />}
              />
              <ListItemLink
                to="/benefits"
                primary="Benefits"
                disabled={selectedTenant === ""}
                icon={<CardGiftcardIcon />}
              />
              <ListItemLink
                to="/payroll"
                primary="Payroll"
                disabled={selectedTenant === ""}
                icon={<LocalAtmIcon />}
              />
              <ListItemLink
                to="/absence"
                primary="Absence"
                disabled={selectedTenant === ""}
                icon={<CardTravelIcon />}
              />
              <ListItemLink
                to="/talent"
                primary="Talent"
                disabled={selectedTenant === ""}
                icon={<StarRoundedIcon />}
              />
              <ListItemLink
                to="/performance"
                primary="Performance Management"
                disabled={selectedTenant === ""}
                icon={<AssessmentIcon />}
              />
              <ListItemLink
                to="/recruiting"
                primary="Recruiting"
                disabled={selectedTenant === ""}
                icon={<FindInPageIcon />}
              />
              <ListItemLink
                to="/learning"
                primary="Learning"
                disabled={selectedTenant === ""}
                icon={<EcoIcon />}
              />
              <ListItemLink
                to="/financials"
                primary="Financials"
                disabled={selectedTenant === ""}
                icon={<TrendingUpIcon />}
              />
              <ListItemLink
                to="/student"
                primary="Student"
                disabled={selectedTenant === ""}
                icon={<SchoolIcon />}
              />
              <ListItemLink
                to="/history"
                primary="Transactional History"
                disabled={selectedTenant === ""}
                icon={<HistoryIcon />}
              />
            </List>
          </Drawer>
          <Container className={classes.body} maxWidth="xl">
            <main>
              <LoadingContext.Provider value={{ isLoading: loading, setIsLoading: setLoading }}>
              <ConsultantContext.Provider value={{ consultant: consultant }}>
              <SelectedTenantContext.Provider value={{ selectedTenant: selectedTenant }}>
              <AdminContext.Provider value={{ isInterfaceAdmin: interfaceAdmin }}>
              <UsernameContext.Provider value={{ username: username }}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/logout" component={logout} />
                  <Route path="/tenants" component={Tenants} />
                  <Route path="/consultants" component={Consultants} />
                  <Route path="/hcm"
                    render={() =>
                      <>
                              <FunctionalArea hubConnection={hubConnection} taskType="Human Capital Management" taskAbbreviation="HCM" />
                          
                      </>
                    }
                  />
                  <Route path="/benefits"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Benefits" taskAbbreviation="Benefits" />
                      </>
                    }
                  />
                  <Route path="/payroll"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Payroll" taskAbbreviation="Payroll" />
                      </>
                    }
                  />
                  <Route path="/absence"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Absence Management" taskAbbreviation="Absence" />
                      </>
                    }
                  />
                  <Route path="/talent"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Talent" taskAbbreviation="Talent" />
                      </>
                    }
                  />
                  <Route path="/performance"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Performance Management" taskAbbreviation="Performance" />
                      </>
                    }
                  />
                  <Route path="/recruiting"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Recruiting" taskAbbreviation="Recruiting" />
                      </>
                    }
                  />
                  <Route path="/learning"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Learning" taskAbbreviation="Learning" />
                      </>
                    }
                  />
                  <Route path="/financials"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Financial Management" taskAbbreviation="Financials" />
                      </>
                    }
                  />
                  <Route path="/student"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Student" taskAbbreviation="Student" />
                      </>
                    }
                  />
                  <Route path="/history"
                    render={() =>
                      <>
                            <FunctionalArea hubConnection={hubConnection} taskType="Transactional History" taskAbbreviation="Transactional History" />
                      </>
                    }
                  />
                  <Route path="/health" component={Health} />
                </Switch>
              </UsernameContext.Provider>
              </AdminContext.Provider>
              </SelectedTenantContext.Provider>
              </ConsultantContext.Provider>
              </LoadingContext.Provider>
            </main>
          </Container>
        </WdCredentialsContext.Provider>
    </div>
  );
};

export default NavMenu;
