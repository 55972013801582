import { Dialog, DialogTitle, DialogContent, DialogContentText, FormGroup, Button } from "@material-ui/core";
import React from "react";

interface DeleteDialogProps {
    isOpen: boolean,
    onDialogClose: any,
    onConfirmDelete: any, 
    recordId: string
}
export const DeleteDialog = (props: DeleteDialogProps): JSX.Element => {
    const { isOpen, onDialogClose, onConfirmDelete, recordId } = props;

    return (
    <Dialog open={isOpen} onClose={onDialogClose}>
      <DialogTitle>Delete record</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this record?
        </DialogContentText>
        <FormGroup>
          <Button onClick={() => onConfirmDelete(recordId)}>Yes</Button>
          <Button onClick={onDialogClose}>No</Button>
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
}
export default DeleteDialog;