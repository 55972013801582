import React from "react";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from './services/AuthService';
import NavMenu from "./components/NavMenu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import { Toaster } from "react-hot-toast";
import { Box, Grid } from "@material-ui/core";
import CustomThemeProvider from "./themes/CustomThemeProvider";
type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => {

  const login = async() => {
    try {
      await pca.loginPopup(loginRequest);
    }
    catch (err) {
      console.error(err);
    }
  }

  return (
    <MsalProvider instance={pca}>
      <CustomThemeProvider>
        <AuthenticatedTemplate>
            <Toaster />
            <NavMenu />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AppBar position="static">
              <Toolbar>
              </Toolbar>
          </AppBar>
          <Box>
            <Grid container justifyContent="center">
            <h1>Not Authenticated</h1>
            </Grid>
            <Grid container justifyContent="center">
              <Button style={{ color: '#000' }}  onClick={() => login()}>Click here to Sign In via Popup</Button>
            </Grid>
          </Box>
        </UnauthenticatedTemplate>
      </CustomThemeProvider>
    </MsalProvider>
  )
};

export default App;
