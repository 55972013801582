import { makeStyles } from "@material-ui/core";

export const useTenantStyles = makeStyles((theme) => ({
    ordersMain: {
      marginTop: theme.spacing(12),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    tableCell: {
      margin: theme.spacing(0)
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    toastDialog: {
      width: '100px'
    },
    formControl: {
      minWidth: 120,
    },
    dateField: {
      marginTop: theme.spacing(0),
    },
  }));