import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ConsultantContext } from '../contexts/ConsultantContext';
import React, { memo, useCallback, useState, useEffect, useContext } from 'react';
import Releases from '../services/Releases';

const useStyles = makeStyles((theme) => ({
    header: {
      marginTop: theme.spacing(12),
    },
    padding: {
      padding: theme.spacing(2),
    },
    root: {
      textAlign: 'center',
    },
    ul: {
      listStyle: 'none'
    }
  })
);

const Home = () => {
  const classes = useStyles('Home');
  const { consultant } = useContext(ConsultantContext);
  const [release, setRelease] = useState<string>('');

  const getRelease = useCallback(async () => {
    const r = await Releases.fetch();
    if(!r.is_error && r.content) {
      setRelease(r.content as string);
    }
  },[]);

  useEffect(() => {
    getRelease();
  }, [getRelease]);

  

  if (consultant === null) {
    return (<div className={classes.root}>
      <div className={clsx(classes.header, classes.padding)}>
        Please contact an Administrator for access to this system.
      </div>
    </div>);
  }
  else {
    return (
      <div className={classes.root}>
        <div className={clsx(classes.header, classes.padding)}>
          <ul className={classes.ul}>
            <li><h2>To get started, please select a tenant from the dropdown in the top-right corner.</h2></li>
            <li><h2>Click the badge icon next to the tenant dropdown to enter your Workday credentials for that tenant.</h2></li>
            <li><h2>Wait 60 seconds for the Encryption process to run and finalize.</h2></li>
            <li><h2>Finally, you may now select a functional area from nav menu on the left and begin working!</h2></li>
            </ul>
          <div><br/></div>
          <ul className={classes.ul}>
            <li><b><h2>ADMINS:</h2></b></li>
            <li><h2>You can configure tenants and consultants using the Cloud and People Icons from the nav menu on the left.</h2></li>
          </ul>
        </div>
        <div>
          <h2>Daytona Release {release}</h2>
        </div>     
      </div>
    );
  }
};

export default memo(Home);
