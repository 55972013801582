import axios, { AxiosRequestConfig, Method } from "axios";

export interface IErrorContent {
  error: string;
  error_description: string;
  [key: string]: string;
}

export interface IRestResponse<T> {
  is_error?: boolean;
  error_content?: IErrorContent;
  content?: T;
}

export default class RestUtilities {
  private static token: string = '';
  public static async setToken(token: string): Promise<void> {
    // If the token passed in is the same as the current one, return.
    // Otherwise, update it.
    if (this.token === token) { console.log('Same token...'); return; }

    console.log('Token is newer, updating...');
    this.token = token;
  }
  static async get<T>(url: string): Promise<IRestResponse<T>> {
    return await RestUtilities.request<T>("GET", url);
  }

  static async delete(url: string): Promise<IRestResponse<void>> {
    return await RestUtilities.request<void>("DELETE", url);
  }

  static async put<T>(url: string, data: Object | string): Promise<IRestResponse<T>> {
    return await RestUtilities.request<T>("PUT", url, data);
  }

  static async post<T>(url: string, data: Object | string): Promise<IRestResponse<T>> {
    return await RestUtilities.request<T>("POST", url, data);
  }

  private static async request<T>(method: string, url: string, data: Object | string = ""): Promise<IRestResponse<T>> {
    let isBadRequest = false;
    let body = data;
    let headers: Record<string, string> = {};
    headers["Authorization"] = `Bearer ${RestUtilities.token}`;

    if (data) {
      if (typeof data === "object") {
        headers["Content-Type"] = "application/json";
        body = data;
      } else {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
      }
    }
    
    const config: AxiosRequestConfig = {
      method: method as Method,
      url: url,
      headers: headers,
      data: body as string,
    };
    
    // axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     const restResponse: IRestResponse<T> = {
    //       is_error: true,
    //       error_content: error.response.statusText,
    //       content: error.response,
    //     };
    //     console.table(restResponse);
    //     return restResponse;
    //   });
    
    const response = await axios(config);

    isBadRequest = response.status > 400;
    const restResponse: IRestResponse<T> = {
      is_error: isBadRequest,
      error_content: isBadRequest ? response.data : null,
      content: isBadRequest ? null : response.data,
    };
    
    return restResponse;
  }
}
