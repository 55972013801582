import * as React from 'react';
import { useState, useEffect, useContext, ChangeEvent, useMemo } from 'react';
import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import TaskRegisterService from "../services/TaskRegisters";
import { IRestResponse } from "../services/RestUtilities";
import { Grid, Checkbox, TablePagination } from "@material-ui/core";
import ITaskRegister, { ITaskRegisterSortable } from '../models/ITaskRegister';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { getComparator, stableSort } from '../util/Sorting';
import SelectedTask from './SelectedTask';
import { SelectedTenantContext } from '../contexts/SelectedTenantContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from '@azure/msal-common';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import IActionNotification from '../models/IActionNotification';
import { ITaskGridProps } from '../models/ITaskGridProps';
import { useTaskGridStyles } from '../themes/TaskGridStyles';
import ITaskRow from '../models/ITaskRow';

interface HeadCell {
    disablePadding: boolean;
    id: keyof ITaskRegisterSortable;
    label: string;
}

const headCells: HeadCell[] = [
    { id: 'taskId', disablePadding: false, label: "Task Id" },
    { id: 'name', disablePadding: false, label: "Name" },
];

interface EnhanceTableProps {
    order: Order;
    orderBy: keyof ITaskRegister;
}

export type Order = 'asc' | 'desc';

const setIsMounted = (isMounted: boolean) => {
    return isMounted;
}

export const TaskGrid = (props: ITaskGridProps) => {
    const { taskType, taskAbbreviation, hubConnection} = props;
    const classes = useTaskGridStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ITaskRegisterSortable>('taskId');
    const [selectedTask, setSelected] = useState<ITaskRegister | null>(null);
    const [tasks, setTasks] = useState<ITaskRegister[]>([]);
    const { accounts, inProgress } = useMsal();
    const account: AccountInfo | null = useAccount(accounts[0]);
    const { selectedTenant } = useContext(SelectedTenantContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { isLoading } = useContext(LoadingContext);
    const isMounted = useMemo<boolean>(() => setIsMounted(true), []); 
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [actionNotification, setActionNotification] = useState<IActionNotification>();

    const getTasks = async (): Promise<void> => {
        try {
            setIsLoading(true);
            let res = await toast.promise<IRestResponse<ITaskRegister[]>>(TaskRegisterService.fetchAll(taskType), {
                loading: 'Fetching Tasks...', 
                success: () => {return `Fetched ${taskType} Tasks` },
                error: (err: Error) => `Task Register Error: ${err.message}. Please see system administrator`,
            });
            let tasks = res.content as ITaskRegister[];
            setTasks(tasks);
        }
        catch(error){
            // AxiosError is used here to get a better understanding of what the error is.
            const ax = error as AxiosError; 
            console.table(ax.response?.data);
        }
        finally{
            setIsLoading(false);
        }
    }

    
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ITaskRegisterSortable) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleCheckBoxClick = (task: ITaskRegister): void => {
        // If the user clicks the same entry again, deselect it.
        if (task.taskId === selectedTask?.taskId) {
            setSelected(null);
        }
        else {
            setSelected({ ...task });
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const updateSelectedTask = (updatedSelectedTask: ITaskRegister) => {
        updateTask(updatedSelectedTask);
    }
    
    const joinHubRoom = () => {
        // invoke method to join user into SiganlR Group using tenant and functional area name
        hubConnection.invoke('JoinRoom', selectedTenant + '_' + taskType).then(() => {
        }).catch((error) => {
            console.table(error);
        });
        hubConnection.on('ReceiveChangeNotification', (notification: IActionNotification) => {
            console.log('Received Signal R Notification');
            setActionNotification(notification);
        });
        
    }

    // Method to Update task in list of tasks that matches taskId and functional area after Signal R notification of change
    // Otherwise a change in functional area will update data automatically
    const updateTask = (task: ITaskRegister) => {
        if ((task.functionalArea === taskType) && isMounted)
        {
            const updatedState: ITaskRegister [] = tasks.map(t => {
                // if task matches id return new task
                if (t.taskId === task.taskId) {
                    return task;
                }
                
                //otherwise return task object
                return t;
            });
            
            setTasks(updatedState);
            if (isSelected(task)){
                setSelected({...task});
            }
        }
    }

    const isSelected = (task: ITaskRegister): boolean => selectedTask?.taskId === task.taskId ?? false;

    const EnhancedTableHead = (props: EnhanceTableProps): JSX.Element => {
        const { order, orderBy } = props;
        const createSortHandler = (property: keyof ITaskRegisterSortable) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" />
                    {headCells.map((headCell: HeadCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const Row = (props: ITaskRow) => {
        const { task, isItemSelected, labelId } = props;
        return (
            <TableRow
                hover
                onClick={() => handleCheckBoxClick(task)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={task.name}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                {
                    headCells.map((val: HeadCell, i: number) => {
                        const { id } = val;
                        return (
                            <TableCell key={i}>{task[id]}</TableCell>
                        )
                    })
                }
            </TableRow>
        );
    };

    useEffect(() => {
        if (inProgress === "none" && account && selectedTenant) {
            getTasks();
            setSelected(null);
        }
        return () => {
            setIsMounted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, account, selectedTenant, taskType]);

    useEffect(() => {
        if(tasks.length > 0)
        {
             // Join room when tenant, connection or taskType changes
            joinHubRoom();
        }

        return () => {
            hubConnection.invoke('LeaveRoom', selectedTenant + '_' + taskType).then(() => {
            }).catch((error) => {
                console.table(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant, taskType, tasks]);

    useEffect(() => {
        // Update task if Signal R actionNotification has been set and selectedTenant matches
        if(actionNotification){
            if(actionNotification.tenant === selectedTenant)
            {
                updateTask(actionNotification.data);
            }
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionNotification]);

    return (
        !isLoading ?
            <Grid container direction={'row'}>
                <Grid item xs={4}>
                    <Box className={classes.tasksMain}>
                        <Title>{taskAbbreviation} Tasks</Title>
                        <TableContainer className={classes.tasksContainer}>
                            <Table stickyHeader={true} aria-label="sticky table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                />
                                
                                <TableBody>
                                    {Array.isArray(tasks) ? stableSort<ITaskRegister>(tasks, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((val: ITaskRegister, i: number) => {
                                            const isItemSelected = isSelected(val);
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <Row
                                                    key={i}
                                                    task={val}
                                                    isItemSelected={isItemSelected}
                                                    labelId={labelId}
                                                />
                                            )
                                        })
                                        : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                                    rowsPerPageOptions={[10, 20, 40, { label: 'All', value: tasks.length }]}
                                    count={tasks.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                        'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    component="div"
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                    </Box>
                </Grid> 
                { /* Selected Task Grid */}
                <Grid item container xs={7}>
                    <Box className={classes.tasksSecondary}>
                        <SelectedTask
                            GroupTitle={'Selected Task'}
                            SelectedTask={selectedTask}
                            RefreshSelected={updateSelectedTask}
                        />
                    </Box>
                </Grid>
            </Grid>
            : <div><h1>Loading...</h1></div>
    );
}
export default TaskGrid;
