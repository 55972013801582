import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ITenant from "../models/ITenant";
import EditIcon from "@material-ui/icons/Edit";
import IServer from "../models/IServer";
import { UsernameContext } from "../contexts/UsernameContext";
import { useTenantStyles } from "../themes/TenantStyles";


interface TenantDialogProps {
  isOpen: boolean;
  onDialogClose: () => void;
  onSubmitEdit: (tenant: ITenant, newTenant?: boolean) => void;
  tenant: ITenant;
  servers: IServer[];
}

export const TenantDialog = (props: TenantDialogProps) => {
  const tenantClasses = useTenantStyles();
  const { isOpen, onDialogClose, onSubmitEdit, tenant, servers } = props;
  // data represents the tenant's dictionary of changed values
  const [data, setData] = useState<ITenant>({ ...tenant });
  const isNewTenant = Object.keys(tenant).length === 0;
  // Set default value for server so that it renders when Editing a tenant
  const [server, setServer] = useState<IServer | null>(servers.find(z => z.datacenter === tenant.implDatacenter) ?? null);
  const { username } = useContext(UsernameContext);

  // TODO: Improve later
  const formatDate = (date: Date): string => {
    // 01, 02, 03, ... 29, 30, 31
    var dd: string = date.getDate().toString().padStart(2, "0");
    // 01, 02, 03, ... 10, 11, 12
    var MM: string = (date.getMonth() + 1).toString().padStart(2, "0");
    // 1970, 1971, ... 2015, 2016, ...
    var yyyy: string = date.getFullYear().toString();

    // create the format you want
    return (yyyy + "-" + MM + "-" + dd);
  }

  const onFormSubmit = () => {
    let date: string;
    // If a value isn't changed in the picker, it'll default to undefined.
    if(!data.expiration) {
      // If a value isn't changed in the picker, use Today's date but add a year onto it
      var datePlusYear = new Date();
      datePlusYear.setFullYear(datePlusYear.getFullYear() + 1);
      date = formatDate(datePlusYear);
    }
    else {
      date = formatDate(new Date(data.expiration));
    }

    console.log(`[${isNewTenant ? 'Creating' : 'Editing'}] tenant [${data.customerName}] with expiration [${date}]`);

    // Set extra properties
    data.expiration = date;
    data.implDatacenter = server?.datacenter ?? '';
    data.updatedBy = username ?? 'unknown';
    data.basePath = server?.baseurl ?? "";

    // Overwrite any properties that have been changed, by merging any changes into ...tenant
    let newTenant: ITenant = { ...tenant, ...data };
    onSubmitEdit(newTenant, isNewTenant);
  }

  const handleServerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selectedServ: IServer = servers.find(s => s.id === event.target.value as number) as IServer;
    setServer(selectedServ);
  };

  const handleDateChange = (date: Date | null, value: string | null | undefined): void => {
    let formattedDate: string = date?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) ?? "";
    setData({ ...data, expiration: formattedDate });
  };

  useEffect(() => {
    // Whenever a tenant is changed, we need to reset state/set defaults.
    setData({ ...tenant });
    setServer(servers.find(z => z.datacenter === tenant.implDatacenter) ?? null);
  }, [tenant]);

  return (
    <Dialog
      open={isOpen}
      onClose={onDialogClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>{isNewTenant ? 'New' : 'Edit'} Tenant</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => { e.preventDefault(); onFormSubmit(); }}>
          <Grid container spacing={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Workday Tenant Name'}
                  defaultValue={tenant['name']}
                  disabled={!isNewTenant}
                  onChange={({ target: { value } }) => setData({ ...data, ['name']: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Customer Name'}
                  defaultValue={tenant.customerName}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, customerName: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl className={tenantClasses.formControl}>
                  <InputLabel>Data Center</InputLabel>
                  <Select
                    required
                    label="Implementation Datacenter"
                    id="server-select"
                    autoWidth
                    value={server?.id ?? ''}
                    // value={server?.id ?? ''}
                    onChange={(e) => handleServerChange(e)}
                  >
                    {servers.map((sv: IServer) => (
                      <MenuItem key={sv.id} value={sv.id}>{sv.datacenter}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{server?.baseurl}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Workday Tenant Report Owner'}
                  defaultValue={tenant.reportOwner}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, reportOwner: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {/* TODO: Value shows previous day likely due to TZ */}
                <KeyboardDatePicker
                  required
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  fullWidth
                  className={tenantClasses.dateField}
                  label="Expiration Date"
                  value={data.expiration}
                  initialFocusedDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                  onChange={(date: Date | null, value: string | null | undefined) => handleDateChange(date, value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Remote SFTP Server'}
                  defaultValue={tenant.sftpServer}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, sftpServer: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Remote SFTP Port Number'}
                  defaultValue={tenant.sftpPortNumber}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, sftpPortNumber: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Remote SFTP User'}
                  defaultValue={tenant.sftpUser}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, sftpUser: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Remote SFTP Password'}
                  defaultValue={tenant.sftpPwd}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, sftpPwd: value })}
                  type={'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'SFTP Input Path'}
                  defaultValue={tenant.inputPath}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, inputPath: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'SFTP Output Path'}
                  defaultValue={tenant.outputPath}
                  disabled={false}
                  onChange={({ target: { value } }) => setData({ ...data, outputPath: value })}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Updated On'}
                  defaultValue={tenant.updated}
                  disabled={true}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div></div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Updated By'}
                  defaultValue={tenant.updatedBy}
                  disabled={true}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div></div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  className={tenantClasses.textField}
                  label={'Created On'}
                  defaultValue={tenant.created}
                  disabled={true}
                  type={'text'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div></div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onDialogClose()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};