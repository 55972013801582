import IAssignment from "../models/IAssignment";

export const initialAssignments: IAssignment[] = [];
export type State = typeof initialAssignments;
export type Action =
  | { type: "add"; newAssignments: IAssignment[] }
  | { type: "remove"; newAssignments: IAssignment[] }
  | { type: "set"; newAssignments: IAssignment[] }
  | { type: "clear" };
// examples
// | { type: 'setLastName', lastName: string }
// | { type: 'setAge', age: number };

const ConsultantReducer = (state: State = initialAssignments, action: Action) => {

  switch (action.type) {
    case "add":
      let newState = [...state, ...action.newAssignments];
      return newState;
    case "remove":
      return [ ...state.filter((val) => !action.newAssignments.includes(val)) ]
    case "set":
      let tmp = [...state, ...action.newAssignments];
      
      return tmp;
    case "clear":
      return [];
    default:
      return state;
  }
};

export default ConsultantReducer;
