import RestUtilities, { IRestResponse } from "./RestUtilities";
import ITaskRegister, { ILockTaskDto, IValidateTaskDto } from "../models/ITaskRegister"
import ITaskRegisterDto from "../dtos/ITaskRegisterDto"
import IVwTaskRegister from "../models/IVwTaskRegister";
import ITaskK8sEventDto from "../dtos/ITaskK8sEventDto";
import ITaskK8sEventsDto from "../dtos/ITaskK8sEventsDto";
export default class TaskRegisters {
  private static baseApi: string = '/api/taskregister';

  static async fetchAll(Area: string): Promise<IRestResponse<ITaskRegister[]>> {
    // TODO: should be passed as parram and test
    const TenantName = localStorage.getItem('selectedTenant') as string;
    const taskRegisterDto: ITaskRegisterDto = { TenantName, Area };
    const tasks = await RestUtilities.post<ITaskRegister[]>(`${TaskRegisters.baseApi + "/All"}`, taskRegisterDto);
    return tasks;
  }

  static async fetchVwTaskRegister(Area: string): Promise<IRestResponse<IVwTaskRegister[]>> {
    // TODO: should be passed as parram and test
    const TenantName = localStorage.getItem('selectedTenant') as string;
    const taskRegisterDto: ITaskRegisterDto = { TenantName, Area };
    return await RestUtilities.post<IVwTaskRegister[]>(`${TaskRegisters.baseApi + "/VwTaskRegister"}`, taskRegisterDto);
  }
 
  static async fetchVwTaskRegisterById(Area: string, TaskId: string): Promise<IRestResponse<IVwTaskRegister>> {
    // TODO: should be passed as parram and test
    const TenantName = localStorage.getItem('selectedTenant') as string; 
    const taskRegisterDto: ITaskRegisterDto = { TenantName, Area, TaskId };
    return await RestUtilities.post<IVwTaskRegister>(`${TaskRegisters.baseApi + "/VwTaskRegisterById"}`, taskRegisterDto);
  }

  static async updateTaskK8sEvents(taskK8sEvent: ITaskK8sEventDto): Promise<IRestResponse<ITaskRegister>> {
    return RestUtilities.post(`${TaskRegisters.baseApi}/UpdateK8sEvents`, taskK8sEvent);
  }
 
  static async updateMultipleTaskK8sEvents(taskK8sEvent: ITaskK8sEventsDto): Promise<IRestResponse<ITaskRegister>> {
    return RestUtilities.post(`${TaskRegisters.baseApi}/UpdateMultipleK8sEvents`, taskK8sEvent);
  }

  static async lockTask(taskLockDto: ILockTaskDto) {
    return await RestUtilities.post<ITaskRegister>(`${TaskRegisters.baseApi + "/LockTask"}`, taskLockDto);
  }
  
  static async validateTask(taskLockDto: IValidateTaskDto) {
    return await RestUtilities.post<ITaskRegister>(`${TaskRegisters.baseApi + "/ValidateTask"}`, taskLockDto);
  }
}