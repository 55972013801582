export async function getGraphUserProfile(accessToken: string): Promise<any> {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
  
    headers.append("Authorization", bearer);
  
    const options = {
      method: "GET",
      headers: headers
    };
  
    const res = await fetch('https://graph.microsoft.com/v1.0/me', options);
    const jsonRes = await res.json();
    return jsonRes;
  } 
  catch (err) {
    console.error(err);
  }
}

export async function getGraphPic(accessToken: string): Promise<Blob> {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
  
    headers.append("Authorization", bearer);
  
    const options = {
      method: "GET",
      headers: headers
    };

    const res = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', options);
    const blobRes = await res.blob();
    return blobRes;
  } 
  catch (err) {
    console.error(err);
    return {} as Blob;
  }
}