import React, { useContext, useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { IconButton, makeStyles, Theme, createStyles } from "@material-ui/core";
import { WdCredentialsContext } from "../contexts/WdCredentialsContext";
import { IRestResponse } from "../services/RestUtilities";
import EtlJobService from "../services/EtlJobs"
import WorkdayService from '../services/WorkdayService';
import IEncryptJobDto from "../dtos/IEncryptJobDto"
import { WorkdayConnectivity } from '../models/IWorkdayConnectivity';
import { AccountInfo } from '@azure/msal-common';
import { useMsal, useAccount } from "@azure/msal-react";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "30ch",
    },
  })
);

interface IWdCredentialsDialogProps {
  consultant: string,
  tenant: string,
  disabled: boolean,
}

export const WdCredentialsDialog = (props: IWdCredentialsDialogProps) : JSX.Element => {
  const classes = useStyles();
  const { consultant, tenant, disabled } = props;
  const { instance, accounts, inProgress } = useMsal();
  const account: AccountInfo | null = useAccount(accounts[0]);
  const [ open, setOpen ] = useState(false);
  const [ implementerId, setImplementerId ] = useState<string>('');
  const [ implementerPwd, setImplementerPwd ] = useState<string>('');
  const { wdImplementerId, setWdImplementerId, wdImplementerPwd, setWdImplementerPwd} = useContext(WdCredentialsContext);

  const setFormState = async() => {
    setImplementerId(wdImplementerId); // using local only here and updating context when logging in
    setImplementerPwd(wdImplementerPwd);  // using local only here and updating context when logging in
  }

  const testWorkdayConnectivity = async(): Promise<void> => {
    let connSettings: WorkdayConnectivity = {
      tenantName: tenant,
      credentials: `${implementerId}:${implementerPwd}`
    }
    await toast.promise<IRestResponse<WorkdayConnectivity>>(WorkdayService.testConnectivity(connSettings), {
      success: 'Success: Workday Classes are available.',
      error: (err: AxiosError) => `Error: Workday Classes not available.\nDetails: ${err?.response?.data ?? 'No details could be provided.'}`,
      loading: 'Testing Workday connectivity now...'
    });
  }

  const credentialsValid = (): Boolean => {
    let exp = new RegExp('^[a-zA-Z0-9-]*$');
    if(exp.test(implementerId) && implementerPwd !== ""){
      return true;
    }
    else{
      toast.error('Invalid Username / Password');
      return false;
    }
  }

  const handleLogin = async (): Promise<void> => {
    
    if(credentialsValid()){
      try {
        const job: IEncryptJobDto = {
          Tenant: tenant,
          Consultant: consultant,
          Password: implementerPwd,
          ImplementerId: implementerId
        };
        // API to create job pdi-process-consultant-assignment.yml
        await toast.promise<IRestResponse<Object>>(EtlJobService.encrypt(job), {
          success: 'Updating credentials...',
          error: (err: AxiosError) => `Error: ${err?.response?.data ?? 'No details could be provided.'}`,
          loading: 'Updating credentials...'
        });
        setWdImplementerId(implementerId);
        setWdImplementerPwd(implementerPwd);
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    if (inProgress === "none" && account && open) {
      setFormState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, account, instance, open]);

  return (
    <div className={classes.root}>
      <IconButton onClick={() => setOpen(true)}
        disabled={disabled}
      >
      <AssignmentIndIcon />
      </IconButton>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update Workday Credentials for {tenant}</DialogTitle>
        <DialogContent>
          <TextField
            value={implementerId}
            margin="normal"
            id="username"
            label="Username"
            className={classes.textField}
            onChange={e => setImplementerId(e.target.value)}
          />
          <TextField
            value={implementerPwd}
            margin="normal"
            id="implementerPwd"
            label="Password"
            type="password"
            className={classes.textField}
            onChange={e => setImplementerPwd(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ padding: '20px'}} >
          <Button 
            style={{ display: 'left' }}
            color="default"
            disabled={!implementerId || !implementerPwd}
            variant="outlined"
            onClick={() => testWorkdayConnectivity()} 
          >
            Test WD Connectivity
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!implementerId || !implementerPwd}
            variant="contained"
            onClick={handleLogin}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WdCredentialsDialog;