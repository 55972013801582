import React from 'react';
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Title from '../components/Title';

const useStyles = makeStyles((theme) => ({
    main: {
      marginTop: theme.spacing(12),
    }
}));

export const Health = () => {
    const classes = useStyles();

    return (
        <Box className={classes.main} >
            <Title>
                Health Status
            </Title>
        </Box>
    );
}

export default Health;