import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#6AA2DC',//Medium Blue
      light: 'rgb(151, 153, 155)', //Medium Grey
      dark: 'rgb(106, 162, 220)',//Medium Blue
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#53565A',
      light: 'rgb(151, 153, 155)',//Medium Grey
      dark: 'rgb(106, 162, 220)',//Medium Blue
      contrastText: '#FFFFFF',
    },
    error: {
      main: red.A400,
    },
  },
})

export default theme