import React, { useState } from 'react';
import { ITaskGridProps } from '../models/ITaskGridProps';
import TaskGrid from '../components/TaskGrid';
import MultiTaskGrid from '../components/MultiTaskGrid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export const FunctionalArea = (props: ITaskGridProps) => {
    const { taskType, taskAbbreviation, hubConnection} = props;
    const [ isSelectMultiple, setIsSelectMultiple] = useState<boolean>(false);

    const handleSelectMultipleChange = () => {
        isSelectMultiple ? setIsSelectMultiple(false) : setIsSelectMultiple(true);
    }
    
    // will either display the task grid with single select or task grid with multi-select
    return (
        <>
            <FormControlLabel
                control={<Switch checked={isSelectMultiple} onChange={handleSelectMultipleChange} name="multiple" />}
                label="Select Multiple"
            />
            {
                !isSelectMultiple ?
                <TaskGrid hubConnection={hubConnection} taskType={taskType} taskAbbreviation={taskAbbreviation} />
                : <MultiTaskGrid hubConnection={hubConnection} taskType={taskType} taskAbbreviation={taskAbbreviation} ></MultiTaskGrid>
            }
        </>
    );
}

export default FunctionalArea;