import RestUtilities, { IRestResponse } from "./RestUtilities";
import IETLJobs from "../models/IJob"
import IK8sJobStatus from "../models/IK8sJobStatus";
import ITaskRegister from "../models/ITaskRegister";
import IEncryptJobDto from "../dtos/IEncryptJobDto";
import IJobDto from "../dtos/IJobDto";

export default class EtlJobsService {
  private static executeJobApi: string = '/api/executejob';
  private static executeMultipleJobsApi: string = '/api/executemultiplejob';

  static extract(job: IJobDto): Promise<IRestResponse<string>> {
    return RestUtilities.post<string>(`${EtlJobsService.executeJobApi}/extract`, job);
  }

  static extractMultiple(job: IETLJobs): Promise<IRestResponse<ITaskRegister[]>> {
    return RestUtilities.post<ITaskRegister[]>(`${EtlJobsService.executeMultipleJobsApi}/extract`, job);
  }

  static transform(job: IJobDto): Promise<IRestResponse<string>> {
    return RestUtilities.post<string>(`${EtlJobsService.executeJobApi}/transform`, job);
  }

  static load(job: IJobDto): Promise<IRestResponse<string>> {
    return RestUtilities.post<string>(`${EtlJobsService.executeJobApi}/load`, job);
  }

  static import(job: IJobDto): Promise<IRestResponse<string>> { 
    return RestUtilities.post<string>(`${EtlJobsService.executeJobApi}/import`, job);
  }
  static encrypt(job: IEncryptJobDto): Promise<IRestResponse<Object>> {
    return RestUtilities.post<IEncryptJobDto>(`${EtlJobsService.executeJobApi}/encrypt`, job);
  }

  static jobStatus(TaskJobName: string, Consultant: string): Promise<IRestResponse<IK8sJobStatus>> {

    return RestUtilities.post<IK8sJobStatus>(`${EtlJobsService.executeJobApi}/jobStatus`, {TaskJobName, Consultant});
  }

  static deleteJob(TaskJobName: string, Consultant: string): Promise<IRestResponse<string>> {

    return RestUtilities.post<string>(`${EtlJobsService.executeJobApi}/deleteJob`, {TaskJobName, Consultant});
  }
}
