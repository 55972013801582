import * as React from 'react';
import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Box, Button, ButtonGroup, Grid, TextField, IconButton, CircularProgress, MenuItem, Menu } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { useJobButtonStyles } from '../themes/JobButtonStyles';
import Title from './Title';
import { CSVLink } from 'react-csv';
import { AccountInfo } from '@azure/msal-common';
import { useMsal, useAccount } from "@azure/msal-react";
import { setApiAccessToken } from '../services/AuthService';
import { toast } from 'react-hot-toast';
import { AxiosError } from "axios";
import TaskErrorGrid, { Csv } from '../pages/TaskErrorGrid';
import PercentComplete from './PercentComplete';
import TaskJobStatusGrid from '../components/TaskJobStatusGrid';
// Context
import { SelectedTenantContext } from '../contexts/SelectedTenantContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { UsernameContext } from '../contexts/UsernameContext';
import { AdminContext } from '../contexts/AdminContext';
import { WdCredentialsContext } from '../contexts/WdCredentialsContext';
// Services, Models, Dtos
import { IRestResponse } from '../services/RestUtilities';
import TaskRegisterService from "../services/TaskRegisters";
import EtlJobsService from '../services/EtlJobs';
import TaskRegisters from '../services/TaskRegisters';
import ITaskRegister, { ILockTaskDto, IValidateTaskDto } from '../models/ITaskRegister';
import IK8sJobStatus from '../models/IK8sJobStatus';
import IVwTaskRegister from '../models/IVwTaskRegister';
import IJobDto from '../dtos/IJobDto';
import ITaskK8sEventDto from '../dtos/ITaskK8sEventDto';

export interface SelectedTaskProps {
  GroupTitle: string;
  SelectedTask: ITaskRegister | null;
  RefreshSelected: (selectedTask: ITaskRegister) => void;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    border: '2px solid #dadde9',
  },
}))(Tooltip);

const setIsMounted = (isMounted: boolean) => {
  return isMounted;
}

export const SelectedTask = (props: SelectedTaskProps) => {
  const { GroupTitle, SelectedTask, RefreshSelected } = props;
  const jobButtonClasses = useJobButtonStyles();
  const { instance, accounts } = useMsal();
  const account: AccountInfo | null = useAccount(accounts[0]);
  const [loading, setLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(SelectedTask?.locked);
  // Contexts
  const { selectedTenant } = useContext(SelectedTenantContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { username } = useContext(UsernameContext);
  const { isInterfaceAdmin } = useContext(AdminContext);
  const { wdImplementerPwd } = useContext(WdCredentialsContext);
  const { wdImplementerId } = useContext(WdCredentialsContext);
  // Dialogs
  const [isTaskErrorDialogOpen, setIsModalOpen] = useState(false);
  const [csv, setCsv] = useState<Csv | null>(null);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [jobType, setJobType] = useState<string>("");
  const [anchorStatusEl, setStatusAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorDeleteEl, setDeleteAnchorEl] = useState<null | HTMLElement>(null);
  const isStatusMenuOpen = Boolean(anchorStatusEl);
  const isDeleteMenuOpen = Boolean(anchorDeleteEl);
  const [taskJobStatus, setTaskJobStatus] = useState<IK8sJobStatus | null>(null);
  const [viewTaskRegister, setViewTaskRegister] = useState<IVwTaskRegister | null>(null); 
  const [isValidateDialogOpen, setIsValidateDialogOpen] = useState(false);
  const [isLoadDialogOpen, setIsLoadDialogOpen] = useState(false);
  const isMounted = useMemo<boolean>(() => setIsMounted(true), []); 
  const [canUnlock, setCanUnlock] = useState<boolean>(false);
  
  const getVwTaskRegisterById = async (): Promise<void> => {
    try {
      if(SelectedTask){
        let resVwTaskRegister = await TaskRegisterService.fetchVwTaskRegisterById(SelectedTask.functionalArea, SelectedTask.taskId);
        let vwTaskRegister = resVwTaskRegister.content as IVwTaskRegister;
        if(isMounted){
          setViewTaskRegister(vwTaskRegister);
        }
      } 
    }
    catch(error){
        // AxiosError is used here to get a better understanding of what the error is.
        const ax = error as AxiosError;
        console.table(ax.response?.data);
    }
  }

  const credentialsValid = (): Boolean => {
    let exp = new RegExp('^[a-zA-Z0-9-]*$');
    
    if(exp.test(wdImplementerId) && wdImplementerPwd !== ""){
      return true;
    }
    else{
      toast.error('Invalid Username / Password');
      return false;
    }
  }

  // Method: Calls EtlJobsService Extract in a try catch statement that returns a promise with a string
  // Validate the password and make sure it's not loading another job
  // Sets Loading to true then call Authservice method SetApiAccessToken
  // Call job service to extract
  // Updates the status returned by a successfull job submitted to AKS.
  const handleExtractOnClick = async (): Promise<void> => {
    if (!loading) {
      try {
        setLoading(true);
        await setApiAccessToken(instance, account!);
        const job: IJobDto = {
          Tenant: selectedTenant,
          Consultant: username,
          Password: wdImplementerPwd,
          JobType: "E",
          Task_Id: SelectedTask?.taskId ?? ""
        }
        let res = await toast.promise<IRestResponse<string>>(EtlJobsService.extract(job), {
          success: 'Success: Job submitted.',
          error: (err: AxiosError) => `Error: ${err.response?.data}`,
          loading: 'Loading...'
        });
        let jobStatusName = res.content as string;
        updateJobStatus(jobStatusName, 'E');
      } 
      catch (error) {
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally {
        setLoading(false);
      }
    }
  }

  // Method: Calls EtlJobsService Transform in a try catch statement that returns a promise with a string
  // Validate the password and make sure it's not loading another job
  // Sets Loading to true then call Authservice method SetApiAccessToken
  // Call job service to transform
  // Updates the status returned by a successfull job submitted to AKS.
  const handleTransformOnClick = async (): Promise<void> => {
    if (!loading) {
      try{
        setLoading(true);
        await setApiAccessToken(instance, account!);
      
        const job: IJobDto = {
          Tenant: selectedTenant,
          Consultant: username,
          Password: wdImplementerPwd,
          JobType: "T",
          Task_Id: SelectedTask?.taskId ?? ""
        }
        
        let res = await toast.promise<IRestResponse<string>>(EtlJobsService.transform(job), {
          success: 'Success: Job submitted.',
          error: (err: AxiosError) => `Error: ${err.response?.data}`,
          loading: 'Loading...'
        });
        let jobStatusName = res.content as string;
        updateJobStatus(jobStatusName, 'T');
      }
      catch (error) {
        // We can log "data" here if we want, but does that contain sensitive info?
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally {
        setLoading(false);
      }
    }
  }

  // Method: Calls EtlJobsService Load in a try catch statement that returns a promise with a string
  // Validate the password and make sure it's not loading another job
  // Sets Loading to true then call Authservice method SetApiAccessToken
  // Call job service to load
  // Updates the status returned by a successfull job submitted to AKS.
  const handleLoadOnClick = async (jobType: string): Promise<void> => {
    const job: IJobDto = {
      Tenant: selectedTenant,
      Consultant: username,
      Password: wdImplementerPwd,
      JobType: "L",
      Task_Id: SelectedTask?.taskId ?? ""
    }
    let res = await toast.promise<IRestResponse<string>>(EtlJobsService.load(job), {
      success: 'Loading Job...',
      error: (err: AxiosError) => `Load Unsuccessful: ${err.response?.data}`,
      loading: 'Processing Load Job...'
    });
    let jobStatusName = res.content as string;
    updateJobStatus(jobStatusName, jobType);
  }

  const validateTask = async (validateFlag: boolean, jobType: string): Promise<void> => {
    if(!loading){
      try{
        if(SelectedTask){
          setLoading(true);
          await setApiAccessToken(instance, account!);
          let task: IValidateTaskDto = {
            functionalArea: SelectedTask?.functionalArea,
            validateButton: validateFlag,
            tenantName: selectedTenant,
            taskId: SelectedTask?.taskId,
          }
          await toast.promise<IRestResponse<ITaskRegister>>(TaskRegisters.validateTask(task), {
            success: 'Success: Job Flag Updated.',
            error: (err: AxiosError) => `Job Task Unsuccessful: ${err.response?.data}`,
            loading: 'Processing Job Flag Change...'
          })
          await handleLoadOnClick(jobType);
        }
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setIsLoadDialogOpen(false);
        setIsValidateDialogOpen(false);
        setLoading(false);
      }
    }
  }

  // Method: Calls EtlJobsService Import in a try catch statement that returns a promise with a string
  // Validate the password and make sure it's not loading another job
  // Sets Loading to true then call Authservice method SetApiAccessToken
  // Call job service to import
  // Updates the status returned by a successfull job submitted to AKS.
  const handleImportOnClick = async (): Promise<void> => {
    if (!loading && credentialsValid()) {
      try{
        setLoading(true);
        await setApiAccessToken(instance, account!);
        const job: IJobDto = {
          Tenant: selectedTenant,
          Consultant: username,
          Password: wdImplementerPwd,
          JobType: "I",
          Task_Id: SelectedTask?.taskId ?? ""
        }
        await toast.promise<IRestResponse<string>>(EtlJobsService.import(job), {
          success: 'Loading Job...',
          error: (err: AxiosError) => `Load Unsuccessful: ${err.response?.data}`,
          loading: 'Processing Load Job...'
        });
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setLoading(false);
      }
    }
  }

  // Locks the task so that they cannot run the job. (Must be admin in order to lock)
  const handleLockOnClick = async (isLocked: boolean): Promise<void> => {
    try{
        setLoading(true);
        await setApiAccessToken(instance, account!);
        if(SelectedTask){
          let task: ILockTaskDto = {
            taskId: SelectedTask?.taskId,
            functionalArea: SelectedTask?.functionalArea,
            locked: isLocked,
            lockedBy: username,
            lastLocked: SelectedTask?.lastLocked,
            tenantName: selectedTenant,
          }
            if (isLocked){
              task.lastLocked = new Date().toDateString();
            }
            else{
              task.lockedBy = '';
              task.lastLocked = '';
          }
          let res = await toast.promise<IRestResponse<ITaskRegister>>(TaskRegisters.lockTask(task), {
            success: 'Success: Task Locked.',
            error: (err: AxiosError) => `Lock Task Unsuccessful: ${err.response?.data}`,
            loading: 'Processing Lock Change...'
          });
          RefreshSelected(res.content as ITaskRegister); // method to update task info up to parent
          setIsLocked(isLocked); 
        }
    }
    catch(error){
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Lock Unsuccessful."));
      }
      console.table(ax.response?.data);
    }
    finally{
      setLoading(false);
    }
  }

  const updateJobStatus = async (taskJobName: string | null, jobType: string): Promise<void> => {
    try {
      setLoading(true);
      const taskK8sEventDto: ITaskK8sEventDto = {
          TaskId: SelectedTask?.taskId ?? "",
          TenantName: selectedTenant,
          FunctionalArea: SelectedTask?.functionalArea ?? "",
          TaskJobName: taskJobName, 
          JobType: jobType, 
      } 
      let res = await toast.promise<IRestResponse<ITaskRegister>>(TaskRegisters.updateTaskK8sEvents(taskK8sEventDto), {
        success: 'Updated Job Status.',
        error: (err: AxiosError) => `Error: ${err.response?.data}`,
        loading: 'Processing Status Change...'
      });
      RefreshSelected(res.content as ITaskRegister);
    } 
    catch (error) {
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Update Status Unsuccessful."));
      }
      console.table(ax.response?.data);
    }
    finally{
        setIsLoading(false);
    }
  }

  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null);
  };

  const handleDeleteJobMenuClose = () => {
    setDeleteAnchorEl(null);
  };

  const handleGetStatus = async (jobType: string) => {
    setStatusAnchorEl(null);
    try {
        setLoading(true);
        let TaskJobName = "";
        setJobType(jobType);
        switch(jobType){
            case "E":
                TaskJobName = SelectedTask?.k8sEventE ?? ""; break;
            case "T":
                TaskJobName = SelectedTask?.k8sEventT ?? ""; break;
            case "L":
                TaskJobName = SelectedTask?.k8sEventL ?? ""; break;
            case "V":
                TaskJobName = SelectedTask?.k8sEventV?? ""; break;
                
        }
        let Consultant = username;
        if(TaskJobName){
            let res = await toast.promise<IRestResponse<IK8sJobStatus>>(EtlJobsService.jobStatus(TaskJobName, Consultant), {
                success: 'Job Status Retrieved',
                error: (err: AxiosError) => `Error: ${err.response?.data}`,
                loading: 'Getting Job Status...'
                });
                let result = res.content as IK8sJobStatus;
                setIsStatusDialogOpen(true);
                setTaskJobStatus(result);
        }
        else {
          toast.error("No job status to display.");
        }
    } catch (error) {
        const ax = error as AxiosError;
        console.table(ax.response?.data);
    }
    finally{
        setLoading(false);
    }
  };

  const handleDeleteJob = async (jobType: string) => {
    setDeleteAnchorEl(null);
    try {
        setLoading(true);
        let TaskJobName = "" as string;
        setJobType(jobType);
        switch(jobType){
            case "E":
                TaskJobName = SelectedTask?.k8sEventE ?? ""; break;
            case "T":
                TaskJobName = SelectedTask?.k8sEventT ?? ""; break;
            case "L":
                TaskJobName = SelectedTask?.k8sEventL ?? ""; break;
            case "V":
                TaskJobName = SelectedTask?.k8sEventL?? ""; break;
                
        }
        
        let Consultant = username;
        if(TaskJobName){
            await toast.promise<IRestResponse<string>>(EtlJobsService.deleteJob(TaskJobName, Consultant), {
                success: 'Job Found. Deleting...',
                error: (err: AxiosError) => `Error: ${err.response?.data}`,
                loading: 'Finding Job...'
            });
            updateJobStatus(null, jobType);
            // display info that job can't be deleted as it is already succeeded
        }
        else {
          toast.error("No job status to delete.");
        }
    } catch (error) {
        const ax = error as AxiosError;
        console.table(ax.response?.data);
    }
    finally{
        setLoading(false);
    }
  }

  const handleJobStatuClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleDeleteButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setDeleteAnchorEl(event.currentTarget);
  };

  const handleCanUnLock = () => {
    if(isLocked) {
      // set canUnlock to true if task is locked and user matches admin or is person who locked
       // set true if you want to not disable
      if ((username === SelectedTask?.lockedBy) || isInterfaceAdmin ) {
        setCanUnlock(true);
      }
      else { // set false if you want to disable
        setCanUnlock(false);
      }
    }
  }

  const showErrorReportClick = (isErrorReportShown: boolean) => {
    setLoading(isErrorReportShown);
    setIsModalOpen(isErrorReportShown);
  }

  const showJobStatusClick = (isJobStatusShown: boolean) => {
    setIsStatusDialogOpen(isJobStatusShown);
  }

  useEffect(() => {
    if (isTaskErrorDialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isTaskErrorDialogOpen]);

  useEffect(() => {
    getVwTaskRegisterById();
    setIsLocked(SelectedTask?.locked);
    return () => {
      setIsMounted(false);
      setViewTaskRegister(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedTask]);

  useEffect(() => {
    if(isLocked){
      handleCanUnLock();
    }
    return() => {
      setCanUnlock(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked]);

  return (
    <>
      { /* Selected Task Grid */}
      <Box className={jobButtonClasses.root}>
        <Title>{GroupTitle}</Title>
        <Grid className={jobButtonClasses.spaced} item container xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Task Id'}
              value={SelectedTask?.taskId ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Name'}
              value={SelectedTask?.name ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Functional Area'}
              value={SelectedTask?.functionalArea ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Status'}
              value={SelectedTask?.completedStatus ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Expected Population'}
              value={SelectedTask?.expectedPopulation ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Actual Population'}
              value={SelectedTask?.actualPopulation ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Raw Data Table'}
              value={SelectedTask?.rawDataTable ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Ready To Load Table'}
              value={SelectedTask?.readyToLoadTable ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Upd Transformation'}
              value={SelectedTask?.updTransformation ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Upd Transformation Date'}
              value={SelectedTask?.updTransformationDate ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Locked By'}
              value={SelectedTask?.lockedBy ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Locked'}
              value={SelectedTask?.locked ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Last Locked'}
              value={SelectedTask?.lastLocked ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Input File'}
              value={SelectedTask?.inputfile ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'RDT Table Name'}
              value={SelectedTask?.rdtTableName || ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'RTL Table Name'}
              value={SelectedTask?.rtlTableName || ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Errors'}
              value={viewTaskRegister?.errors || ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Warnings'}
              value={viewTaskRegister?.warnings || ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Requests Count'}
              value={SelectedTask?.requestsCount ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Responses Count'}
              value={SelectedTask?.responsesCount ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Batch Count'}
              value={viewTaskRegister?.batchCount ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              label={'Batch Response Count'}
              value={viewTaskRegister?.batchResponsesCount ?? ''}
              className={jobButtonClasses.textField}
              InputProps={{
                readOnly: true
              }}
              variant={'filled'}
            />
          </Grid>
        </Grid>
        {
          SelectedTask && <PercentComplete vwTaskRegister={viewTaskRegister}></PercentComplete>
        }
        <Box className={jobButtonClasses.interactiveGroupSpacing}>
        { /* Run Jobs Buttons Group */ }
          {
            isLocked ?
            <IconButton onClick={() => handleLockOnClick(false)} disabled={(canUnlock) ? false : true} aria-label="outlined"> <LockIcon/></IconButton> :
            <IconButton onClick={() => handleLockOnClick(true)} disabled={SelectedTask ? false : true}> <LockOpenIcon /> </IconButton>
          }
          <ButtonGroup  variant="text" className={jobButtonClasses.buttons} color="primary" aria-label="outlined primary button group">
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => handleExtractOnClick()}
              disabled={((SelectedTask && isLocked === false) && SelectedTask.extractButton) ? false : true}
            >Extract
            {loading && <CircularProgress size={24} className={jobButtonClasses.buttonProgress} />}
            </Button>
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => handleTransformOnClick()}
              disabled={(SelectedTask && isLocked === false) && SelectedTask.transformButton ?  false : true}
            >Transform
            {loading && <CircularProgress size={24} className={jobButtonClasses.buttonProgress} />}
            </Button>
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => setIsValidateDialogOpen(true)}
              disabled={(SelectedTask && isLocked === false) && SelectedTask.loadButton ? false : true}
            >Validate
            {loading && <CircularProgress size={24} className={jobButtonClasses.buttonProgress} />}
            </Button>
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => setIsLoadDialogOpen(true)}
              disabled={(SelectedTask && isLocked === false) && SelectedTask.loadButton ? false : true}
            >Load
            {loading && <CircularProgress size={24} className={jobButtonClasses.buttonProgress} />}
            </Button>
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => handleImportOnClick()}
              disabled={(SelectedTask && isLocked === false) && SelectedTask.importButton ? false : true}
              >Import Errors
              {loading && <CircularProgress size={24} className={jobButtonClasses.buttonProgress} />}
            </Button>
            <Button
              style={{ minWidth: "100px" }}
              onClick={() => showErrorReportClick(true)}
              disabled={SelectedTask ? false : true}
              >Error Log
            </Button>
            <Button
              style={{ minWidth: "100px" }}
              disabled={SelectedTask ? false : true}
              id="job-status-button"
              aria-controls={isStatusMenuOpen ? 'status-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isStatusMenuOpen ? 'true' : undefined}
              onClick={handleJobStatuClick}
            >
              Job Status
            </Button>
            <Button 
              className={jobButtonClasses.buttonKill} 
              style={{ minWidth: "100px" }}
              disabled={SelectedTask ? false : true}
              id="kill-job-button"
              aria-controls={isDeleteMenuOpen ? 'delete-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isDeleteMenuOpen ? 'true' : undefined}
              onClick={handleDeleteButtonClick}
            >
              Kill Job
            </Button>
          </ButtonGroup>
          <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Kill Job:</Typography>
                  <ul>
                    <li><em>{"Deletes Job from Kubernetes."}</em> </li> 
                    <li><em>{"Won't delete succeeded jobs."}</em></li>
                    <li><em>{"Won't delete failed jobs."}</em></li>
                  </ul>
                </React.Fragment>
              }
          >
              <InfoIcon fontSize="small" style={{ color: '#2F78C4' }}></InfoIcon>
          </HtmlTooltip>
          { /* Menu For Jobs Status*/ }
          <Menu
            id="status-positioned-menu"
            aria-labelledby="job-status-button"
            anchorEl={anchorStatusEl}
            open={isStatusMenuOpen}
            onClose={handleStatusMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => handleGetStatus('E')}>Extract</MenuItem>
            <MenuItem onClick={() => handleGetStatus('T')}>Transform</MenuItem>
            <MenuItem onClick={() => handleGetStatus('V')}>Validate</MenuItem>
            <MenuItem onClick={() => handleGetStatus('L')}>Load</MenuItem>
          </Menu>  
          { /* Menu For Jobs Deletion*/ }
          <Menu
            id="delete-positioned-menu"
            aria-labelledby="job-delete-button"
            anchorEl={anchorDeleteEl}
            open={isDeleteMenuOpen}
            onClose={handleDeleteJobMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => handleDeleteJob('E')}>Extract</MenuItem>
            <MenuItem onClick={() => handleDeleteJob('T')}>Tranform</MenuItem>
            <MenuItem onClick={() => handleDeleteJob('V')}>Validate</MenuItem>
            <MenuItem onClick={() => handleDeleteJob('L')}>Load</MenuItem>
          </Menu>
        </Box>
      </Box>
      { /* Error Dialog*/ }
      <Dialog
        open={isTaskErrorDialogOpen}
        onClose={() => showErrorReportClick(false)}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-error-report"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle>
          Error Log for {SelectedTask?.taskId} { SelectedTask?.name} with input file { SelectedTask?.inputfile }
        </DialogTitle>
        <DialogContent dividers={true}>
          <TaskErrorGrid setCsv={setCsv} selectedTaskId={SelectedTask?.taskId}/>
        </DialogContent>
        <DialogActions>
        {csv && <CSVLink {...csv}><Button>Export CSV</Button></CSVLink>}
          <Button onClick={() => showErrorReportClick(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      { /* Job Status Dialog */ }
      <Dialog
        open={isStatusDialogOpen}
        onClose={() => showJobStatusClick(false)}
        scroll={'paper'}
        aria-labelledby="status-dialog-title"
        aria-describedby="status-dialog-description"
        maxWidth="md"
      >
        <DialogTitle>
         {jobType} job status for {SelectedTask?.taskId} { SelectedTask?.name}
        </DialogTitle>
        <DialogContent dividers={true}>
          <TaskJobStatusGrid taskJobStatus={taskJobStatus}></TaskJobStatusGrid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showJobStatusClick(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      { /* Verify Validate Dialog */ }
      <Dialog
        open={isValidateDialogOpen}
        aria-labelledby="validate-dialog"
        aria-describedby="validate-dialog-description"
        maxWidth="md"
      >
        <DialogContent dividers={true}>
          Are you sure you want to Validate?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => validateTask(true, 'V')}>Yes</Button>
          <Button onClick={() => setIsValidateDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      { /* Verify Load Dialog */ }
      <Dialog
        open={isLoadDialogOpen}
        aria-labelledby="load-dialog"
        aria-describedby="load-dialog-description"
        maxWidth="md"
      >
        <DialogContent dividers={true}>
          Are you sure you want to Load?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => validateTask(false, 'L')}>Yes</Button>
          <Button onClick={() => setIsLoadDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
};
export default SelectedTask;