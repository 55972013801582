import { makeStyles, createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useTaskGridStyles = makeStyles((theme: Theme) => 
    createStyles({
        tasksMain: {
            marginTop: theme.spacing(0),
        },
        tasksSecondary: {
            marginTop: theme.spacing(0),
        },
        textField: {
            marginBottom: theme.spacing(2),
        },
        tableCell: {
            margin: theme.spacing(0)
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        tasksContainer:  {
            maxHeight: 900,
            maxWidth: "99%",
            marginLeft: "1%",
            "&::-webkit-scrollbar": {
                width: 20
            },
            "&::-webkit-scrollbar-track": {
                borderRadius: 1
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor:'#e3e3e3',
                borderRadius: 2
            }
        },
    })
);