import RestUtilities, { IRestResponse } from "./RestUtilities";
import { IConsultant } from "../models/IConsultant";

export default class Consultants {
  static async fetchAll(): Promise<IRestResponse<IConsultant[]>> {
    return await RestUtilities.get<IConsultant[]>("/api/consultant");
  }

  static async fetchByUsername(username: string): Promise<IRestResponse<IConsultant>> {
    return await RestUtilities.get<IConsultant>(`/api/consultant/ByUsername/${username}`);
  }

  static async fetchByUseremail(email: string): Promise<IRestResponse<IConsultant>> {
    return await RestUtilities.get<IConsultant>(`/api/consultant/ByUseremail/${email}`);
  }

  static async search(query: string): Promise<IRestResponse<IConsultant[]>> {
    return await RestUtilities.get<IConsultant[]>(`/api/consultant/search/?q=${query}`);
  }

  static async update(consultant: IConsultant): Promise<IRestResponse<IConsultant>> {
    return await RestUtilities.put<IConsultant>(`/api/consultant/${consultant.username}`, consultant);
  }

  static async create(consultant: IConsultant) {
    return await RestUtilities.post<IConsultant>("/api/consultant", consultant);
  }

  static async save(consultant: IConsultant) {
    if (consultant.username) {
      return await this.update(consultant);
    } else {
      return await this.create(consultant);
    }
  }

  static async delete(consultantUserName: string) {
    return await RestUtilities.delete(`/api/consultant/${consultantUserName}`);
  }
}
