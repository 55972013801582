import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import getTheme from './base';
import { CustomThemeContext } from '../contexts/CustomThemeContext';

// eslint-disable-next-line no-unused-vars
const CustomThemeProvider = (props: any) => {

  // eslint-disable-next-line react/prop-types
  const { children } = props;

  // Read current theme from localStorage or maybe from an api
  const currentTheme = localStorage.getItem('theme') || 'dark'

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(currentTheme)

  // Retrieve the theme object by theme name
  const theme = getTheme(themeName)

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name: string) => {
    localStorage.setItem('theme', name);
    _setThemeName(name)
  }

  return (
    <CustomThemeContext.Provider value={{ currentTheme: themeName, setTheme: setThemeName }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  )
}

export default CustomThemeProvider;