import React, { useState, useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { ListSubheader } from "@material-ui/core";
import { AssignmentsContext } from "../contexts/AssignmentContext"
import IAssignment from "../models/IAssignment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    paper: {
      width: 300,
      height: 300,
      overflow: "auto",
      marginLeft: theme.spacing(0)
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    listSubheader: {
      height: "100%",
      lineHeight: "22px",
      backgroundColor: "#333"
    },
  })
);

function not<T>(a: T[], b: T[]): T[] {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection<T>(a: T[], b: T[]): T[] {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface TransferListProps {
  availableTenants: IAssignment[];
}

export const TransferList = (props: TransferListProps) => {
  const { availableTenants } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState<IAssignment[]>([]);
  const [available, setAvailable] = useState<IAssignment[]>(availableTenants);

  const { state: assignmentState, dispatch } = useContext(AssignmentsContext);
  const leftChecked: IAssignment[] = intersection(checked, available);
  const rightChecked: IAssignment[] = intersection(checked, assignmentState);

  const handleToggle = (value: IAssignment) => () => {
    const currentIndex: number = checked.indexOf(value);
    const newChecked: IAssignment[] = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = (): void => {
    dispatch({ type: "add", newAssignments: available });
    setAvailable([]);
  };

  const handleCheckedRight = (): void => {
    dispatch({ type: "add", newAssignments: leftChecked });
    setAvailable(not(available, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = (): void => {
    dispatch({ type: "remove", newAssignments: rightChecked });
    setAvailable(available.concat(rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = (): void => {
    setAvailable(available.concat(assignmentState));
    dispatch({ type: "clear" });
  };

  const customList = (items: IAssignment[], title: string): JSX.Element => (
    <Paper className={classes.paper}>
      <List
        dense
        component="div"
        role="list"
        subheader={
          <ListSubheader className={classes.listSubheader}>
            {title}
          </ListSubheader>
        }
      >
        {items.map((value: IAssignment) => {
          const labelId = `transfer-list-item-${value.tenant}-label`;
          return (
            <ListItem
              dense
              key={value.tenant}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.tenant} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList( available, "Available Tenants" )}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={available.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={assignmentState.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList( assignmentState, "Assigned Tenants" )}</Grid>
    </Grid>
  );
}
export default TransferList;
