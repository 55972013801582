import RestUtilities, { IRestResponse } from "./RestUtilities";
import IVwErrorTableTaskSummary from "../models/IVwErrorTableTaskSummary";
import IErrorTableTaskFetchDto from "../dtos/IErrorTableTaskSummaryDto";

export default class TaskErrors {
  private static baseApi: string = '/api/errortable';
  static async fetchByTask(TaskId: string): Promise<IRestResponse<IVwErrorTableTaskSummary[]>> {
    const TenantName = localStorage.getItem('selectedTenant') as string;
    const taskDto: IErrorTableTaskFetchDto = { TenantName, TaskId};
    const tasks = await RestUtilities.post<[IVwErrorTableTaskSummary]>(`${TaskErrors.baseApi + "/ErrorsByTask"}`, taskDto);
    return tasks;
  }
}
