import RestUtilities, { IRestResponse } from "./RestUtilities";
import ITenant from "../models/ITenant"

export default class Tenants {

  static async fetchAll(): Promise<IRestResponse<ITenant[]>> {
    return await RestUtilities.get<Array<ITenant>>("/api/tenant");
  }

  static async fetch(name: string) {
    return await RestUtilities.get<ITenant>(`/api/tenant/${name}`);
  }
  static async search(query: string) {
    return await RestUtilities.get<Array<ITenant>>(`/api/tenant/search/?q=${query}`);
  }

  static async update(tenant: ITenant) {
    return await RestUtilities.put<ITenant>(`/api/tenant/${tenant.name}`, tenant);
  }

  static async create(tenant: ITenant) {
    return await RestUtilities.post<ITenant>("/api/tenant", tenant);
  }

  static async save(tenant: ITenant) {
    if (tenant.name) {
      return await this.update(tenant);
    } else {
      return await this.create(tenant);
    }
  }
  
  static async delete(tenantId: string, consultant: string) {
    return await RestUtilities.delete(`/api/tenant/${tenantId}/${consultant}`);
  }
}
