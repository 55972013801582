import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#6AA2DC',//Medium Blue
    },
    secondary: {
      main: '#53565A',//Dark Gray
    },
    error: {
      main: red.A400,
    },
  },
})
export default theme