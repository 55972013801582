import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useMemo, } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

interface IListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  disabled: boolean;
}
export const ListItemLink = (props: IListItemLinkProps) => {
    const { icon, primary, to, disabled } = props;
  
    const renderLink = useMemo(() =>
        React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
        )),
        [to]
    );

    return (
        <li>
        <ListItem button component={renderLink} disabled={disabled}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
        </ListItem>
        </li>
    );
}

export default ListItemLink;