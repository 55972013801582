import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { Button, Checkbox } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "../components/Title";
import TenantService from "../services/Tenants";
import ServerService from "../services/Servers";
import IServer from "../models/IServer";
import ITenant from "../models/ITenant";
import { IRestResponse } from "../services/RestUtilities";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { TenantDialog } from '../components/TenantDialog';
import { LoadingContext } from '../contexts/LoadingContext';
import { ConsultantContext } from '../contexts/ConsultantContext';
import { UsernameContext } from '../contexts/UsernameContext';
import { AccountInfo } from '@azure/msal-common';
import { useMsal, useAccount } from "@azure/msal-react";
import { setApiAccessToken } from '../services/AuthService';
import toast from 'react-hot-toast';
import { AxiosError } from "axios";
import { useTenantStyles } from "../themes/TenantStyles";
import DeleteDialog from "./DeleteDialog";

export interface IDataField {
  id: number;
  title: string;
  key: string;
  type: string;
}

const setIsMounted = (isMounted: boolean) => {
  return isMounted;
}

export const Tenants = (): JSX.Element => {
  const tenantClasses = useTenantStyles();
  const [tenants, setTenants] = useState<ITenant[]>([]);
  const [servers, setServers] = useState<IServer[]>([]);
  const [deleteDialogOn, setDeleteDialogOn] = useState(false);
  const [editDialogOn, setEditDialogOn] = useState(false);
  const [recordIdToDelete, setRecordIdToDelete] = useState<string>('');
  const [tenantToEdit, setTenantToEdit] = useState<string | null>(null);
  const { instance, accounts, inProgress } = useMsal();
  const account: AccountInfo | null = useAccount(accounts[0]);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { username } = useContext(UsernameContext);
  const { consultant } = useContext(ConsultantContext);
  const isMounted = useMemo<boolean>(() => setIsMounted(true), []); 

  const onEditDialogOpen = (name: string) => {
    setTenantToEdit(name);
    setEditDialogOn(true)
  };

  const onDeleteDialogOpen = (name: string) => {
    setDeleteDialogOn(true);
    setRecordIdToDelete(name);
  };

  // Create/Edit Tenant
  const handleEdit = async (tenant: ITenant, newTenant: boolean = false) => {
    setEditDialogOn(false);
    setIsLoading(true);
    // Add new tenant to control DB
    if (newTenant) {
      try{
        await toast.promise(TenantService.create(tenant), {
          loading: 'Processing Create Tenant...',
          success: 'Success: Tenant Created.',
          error: (err: AxiosError) => `Create Tenant Unsuccessful: ${err.response?.statusText}`
        })
        setTenants([...tenants, tenant]);
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setIsLoading(false);
      }
    }
    // Update Tenant in control DB
    else {
      try{
        await toast.promise(TenantService.update(tenant), {
          loading: 'Processing Tenant Update...',
          success: 'Success: Tenant Updated.',
          error: (err: AxiosError) => `Update Tenant Unsuccessful: ${err.response?.statusText}`
        });
        let tableDataCopy = [...tenants];
        let editedItemIdx = tableDataCopy.findIndex(({ name }: ITenant) => name === tenant.name); 
        tableDataCopy.splice(editedItemIdx, 1, tenant);
        setTenants(tableDataCopy);
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setIsLoading(false);
      }
    }
  };

  const handleDelete = async(tenantName: string) => {
    setDeleteDialogOn(false);
    if(!tenantName){
      console.log('Tenant is null for some reason...');
    }
    else {
      try{
        setIsLoading(true);
        await toast.promise(TenantService.delete(tenantName, username), {
          loading: 'Processing Delete Tenant...',
          success: 'Success: Tenant Deleted.',
          error: (err: AxiosError) => `Delete Tenant Unsuccessful: ${err.response?.statusText}`
        }, { 
          // some theme if necessary
        });
        const tableDataCopy = [...tenants];
        setTenants(tableDataCopy.filter(({ name }) => name !== tenantName));
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setIsLoading(false);
      }
      
    }
  };

  const getTenants = async (): Promise<void> => {
    try {
      setIsLoading(true);
      // Account is checked for being null in the useEffect, use ! to override typescript error.
      await setApiAccessToken(instance, account!);
      let response: IRestResponse<ITenant[]> = await TenantService.fetchAll();
      let tenants = response.content as ITenant[];
      const tableDataCopy = [...tenants];
      if(isMounted){
        setTenants(tableDataCopy);
      }
    }
    catch (error){
        const ax = error as AxiosError;
        if(ax.response?.statusText)
        {
          toast(ax.response?.statusText.concat(". Tenant Request Unsuccessful."));
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const getServers = async (): Promise<void> => {
    try {
      setIsLoading(true);
      // Account is checked for being null in the useEffect, use ! to override typescript error.
      await setApiAccessToken(instance, account!);
      let response: IRestResponse<IServer[]> = await ServerService.fetchAll();
      let servers = response.content as IServer[];
      const tableDataCopy = [...servers];
      if(isMounted){
        setServers(tableDataCopy);
      }
    }
    catch(error){
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Server Request Unsuccessful."));
      }
    }
    finally{
      setIsLoading(false);
    }
  };

  const dataFields: IDataField[] = [
    { id: 0, title: "Workday Tenant Name", key: "name", type: "string" },
    { id: 1, title: "Customer Name", key: "customerName", type: "string" },
    { id: 2, title: "Implementation Datacenter", key: "implDatacenter", type: "string" },
    { id: 3, title: "Workday Tenant Report Owner", key: "reportOwner", type: "string" },
    { id: 4, title: "Base Path", key: "basePath", type: "string" },
    { id: 5, title: "Remote SFTP Server", key: "sftpServer", type: "string" },
    { id: 6, title: "Created On", key: "created", type: "string" },
    { id: 7, title: "Updated On", key: "updated", type: "string" },
    { id: 8, title: "Updated By", key: "updatedBy", type: "string" },
    { id: 9, title: "Expires On", key: "expiration", type: "string" },
  ];

  useEffect(() => {
    setIsMounted(true);
    if (inProgress === "none" && account) {
      getTenants();
      getServers();
    }
    return () => {
      setIsMounted(false);
    }
  }, [inProgress, account, instance]);

  const Header = ({ columnTitles }: any) => (
    <TableHead>
      <TableRow>
        {columnTitles.map(({ title, id }: any) => (
          <TableCell key={id}>{title}</TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  interface ICellProps {
    data: any;
    index: number;
  }

  const Cell = ({ data, index }: ICellProps) => {
    if (typeof data !== "boolean") {
      return <TableCell key={index}>{data}</TableCell>;
    }
    else {
      return (
        <TableCell key={index}>
          <Checkbox checked={data} disabled={true} />
        </TableCell>
      );
    }
  };

  interface IRowProps {
    tenant: ITenant;
    columns: IDataField[];
    onEdit: (name: string) => void;
    onDelete: (name: string) => void;
  }

  const handleNewTenantClick = (): void => {
    setTenantToEdit(null);
    setEditDialogOn(true);
  }

  const Row = (props: IRowProps) => {
    const { tenant, columns, onEdit, onDelete } = props;
    return (
      <TableRow>
        {columns.map(({ key }: IDataField, i: number) => (
          <Cell key={i} data={tenant[key]} index={i}></Cell>
        ))}
        <TableCell>
          <IconButton
            onClick={() => onEdit(tenant.name)}
            disabled={!consultant?.interfaceAdmin}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={new Date(tenant.expiration) > new Date()}
            onClick={() => onDelete(tenant.name)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };


  return (
    !isLoading ?
      <div>
        <DeleteDialog
          isOpen={deleteDialogOn}
          onDialogClose={() => setDeleteDialogOn(false)}
          onConfirmDelete={handleDelete}
          recordId={recordIdToDelete}
        />
        <TenantDialog
          isOpen={editDialogOn}
          onDialogClose={() => setEditDialogOn(false)}
          onSubmitEdit={handleEdit}
          tenant={tenants.find(({ name }) => name === tenantToEdit) ?? {} as ITenant}
          servers={servers}
        />
        <Box className={tenantClasses.ordersMain}>
          <div>
            <Title>
              Tenants
              <Button
                onClick={() => handleNewTenantClick()}
                disabled={!consultant?.interfaceAdmin}
                variant="contained"
                color="primary">
                New Tenant
              </Button>
            </Title>
          </div>
          <TableContainer>
            <Table size="small">
              <Header columnTitles={dataFields} />
              <TableBody>
                {tenants.map((tenant) => (
                  <Row
                    key={tenant.name}
                    tenant={tenant}
                    columns={dataFields}
                    onEdit={onEditDialogOpen}
                    onDelete={onDeleteDialogOpen}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box >
      </div >
      : <div><h1>Loading...</h1></div>
  );
};

export default Tenants;