import { makeStyles, createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const useJobButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: '100%'
    },
    textField: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    interactiveGroupSpacing: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(0)
    },
    buttons: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(3), 
      marginBottom: theme.spacing(4)
    },
    spaced: {
      marginTop: theme.spacing(4)
    },
    buttonProgress: {
      color: '#6AA2DC',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonKill: {
      '&:hover': {
        backgroundColor: '#ff0000',
        color:'rgb(26, 35, 39)',
        fontColor: 'rrgb(0 0 0)',
        fontWeight: 'bold',
      }
    }
  })
);