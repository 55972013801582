import { makeStyles } from "@material-ui/core";

export const useConsultantStyles = makeStyles((theme) => ({
    ordersMain: {
      marginTop: theme.spacing(12),
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    window: {
      height: 250,
      maxWidth: 250,
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
  }));