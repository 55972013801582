import React from 'react';
import { Box, createStyles, Grid, makeStyles, Theme, LinearProgress, Typography } from '@material-ui/core';
import IVwTaskRegister from '../models/IVwTaskRegister';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linearProgress: {
      height: 10,
      borderRadius: 5,
      marginTop: 5
    },
    linearBox: {
      marginLeft: '10px',
      marginRight: '10px'
    }
  })
);
export interface VwTaskRegisterProps {
    vwTaskRegister: IVwTaskRegister | null ;
}
export const PercentComplete = (props: VwTaskRegisterProps): JSX.Element => {
    const classes = useStyles();
    const { vwTaskRegister } = props;
  return (
    <>
        <Grid>
            <Box className={classes.linearBox}>
                <Typography 
                variant="body2" 
                color="textSecondary">
                    Percent Complete: {`${vwTaskRegister?.percentComplete} %`}
                </Typography>
            </Box>
        </Grid>
        <Grid container item >
            <Box className={classes.linearBox} sx={{ flexGrow: 1 }}>
                <LinearProgress 
                className={classes.linearProgress} 
                variant="determinate" 
                value={vwTaskRegister?.percentComplete ? vwTaskRegister?.percentComplete : 0} 
                />
            </Box>
        </Grid>
    </>
  );
}

export default PercentComplete;