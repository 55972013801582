import { Configuration, PopupRequest, AuthenticationResult, IPublicClientApplication, LogLevel, ILoggerCallback } from "@azure/msal-browser";
import { AccountInfo } from '@azure/msal-common';
import RestUtilities from "./RestUtilities";

const executeCallback: ILoggerCallback = (level, message, containsPii) => {
  if (containsPii) {
    return;
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
}

const getClientId = (): string => {
  // Copy and paste a GUID from below to change ENV when running locally
  let clientId = "96d952ed-e627-4cc0-a97a-c77129f9f169";  // default Daytona Dev (app registration)

  // window.location.host includes IP address and not URL
  if (window.location.host.toLowerCase().includes("daytona")) {
    if (window.location.host.toLowerCase().includes("dev")) {
      clientId = '96d952ed-e627-4cc0-a97a-c77129f9f169'   // DEV
      console.log('wlh:', window.location.host);
    }
    else if (window.location.host.toLowerCase().includes("test")) {
      clientId = 'bf488bb4-9a5c-495d-a474-425a0faccc03'   // TST
      console.log('wlh:', window.location.host);
    }
    else if (window.location.host.toLowerCase().includes("apac")) { 
        clientId = 'b4134ef8-a38d-403a-9c5b-9b09fd510a59' // SEA
        console.log('wlh:', window.location.host);        
    }
    else { // Production
      clientId = '3a3a3bc2-c313-47b0-bd68-b192f62d286a'   // PRD
      console.log('wlh:', window.location.host);
    }
  }
  return clientId;
}

export const loginRequest: PopupRequest = {
  scopes: [
    "api://" + getClientId() + '/user_impersonation',
    // 'user.read',
    // 'openid',
    // 'profile',
  ]
}
export const graphRequest: PopupRequest = {
  scopes: [
    'user.read',
    'openid',
    'profile',
  ]
}

// Msal Configurations
export const msalConfig: Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/c8090595-d8cb-4482-8ff3-0fea87eea80b",
    clientId: getClientId(),    // Daytona Dev App Reg
    redirectUri: window.location.origin,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  },
  system: {
    loggerOptions: {
      loggerCallback: executeCallback
    }
  }
};

export const getGraphAccessToken = async (instance: IPublicClientApplication, account: AccountInfo): Promise<string> => {
  let token = '';
  try {
    // Try acquiring Token silently
    let authRes: AuthenticationResult = await instance.acquireTokenSilent({ ...graphRequest, account: account });
    token = authRes.accessToken;
  }
  catch (err) {
    let authRes: AuthenticationResult = await instance.acquireTokenPopup({ ...graphRequest, account: account });
    token = authRes.accessToken;
  }
  if (!token) {
    console.warn('getGraphAccessToken -> Token is null for some reason...');
  }
  return token;
}

export const setApiAccessToken = async (instance: IPublicClientApplication, account: AccountInfo): Promise<void> => {
  let token = '';
  try {
    // Try acquiring Token silently
    console.warn('setApiAccessToken -> acquire token silent...');
    let authRes: AuthenticationResult = await instance.acquireTokenSilent({ ...loginRequest, account: account });
    token = authRes.accessToken;
  }
  catch (err) {
    console.warn('setApiAccessToken -> acquire token popup...');
    let authRes: AuthenticationResult = await instance.acquireTokenPopup({ ...loginRequest, account: account });
    token = authRes.accessToken;
  }
  if (!token) {
    console.warn('setApiAccessToken -> Token is null for some reason...');
  }
  // Set token in the Rest Util class for use by Axios Auth Headers
  RestUtilities.setToken(token);
}