import * as React from 'react';
import { useState, useEffect } from 'react';
import IVwErrorTableTaskSummary, { IVwErrorTableTaskSummarySortable } from '../models/IVwErrorTableTaskSummary';
import TaskErrorService from '../services/TaskErrors';
import { Grid, TableContainer, TableBody, TableRow, Table, TableCell, TableHead, TableSortLabel, Box } from '@material-ui/core';
import toast from 'react-hot-toast';
import { IRestResponse } from '../services/RestUtilities';
import { AxiosError } from 'axios';

export type Order = 'asc' | 'desc';
interface EnhanceTableProps {
    order: Order;
    orderBy: keyof IVwErrorTableTaskSummary;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof IVwErrorTableTaskSummarySortable;
    label: string;
}

const headCells: HeadCell[] = [
    { id: 'errorType', disablePadding: false,  label: "Error Type" },
    { id: 'errorMessage', disablePadding: false,  label: "Error Message" },
    { id: 'count', disablePadding: false,  label: "Count" },
];

interface ErrorTableHeaders {
    label: string;
    key: string;
}

const headers: ErrorTableHeaders[] = [
    { label: "TaskId", key: "taskId" },
    { label: "Name", key: "name" },
    { label: "Input File", key: "inputfile" },
    { label: "Error Type", key: "errorType" },
    { label: "Error Message", key: "errorMessage" },
    { label: "Count", key: "count" }
  ];

export interface TaskErrorGridProps {
    selectedTaskId: string | undefined;
    setCsv: Function;
}

export interface Csv {
    filename: string;
    headers: ErrorTableHeaders[];
    data: IVwErrorTableTaskSummary[];
}

export const TaskErrorGrid = (props: TaskErrorGridProps) => {
    const { setCsv, selectedTaskId }  = props;
    const [taskErrors, setErrorTableTaskSummary] = useState<IVwErrorTableTaskSummary[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IVwErrorTableTaskSummarySortable>('errorType');
    const [loading, setLoading] = useState(false);
    let isMounted: boolean;

    const getErrorTableTaskSummary = async (): Promise<void> => {
        if(selectedTaskId !== undefined) {
            try {
                setLoading(true);
                let res = await toast.promise<IRestResponse<IVwErrorTableTaskSummary[]>>(TaskErrorService.fetchByTask(selectedTaskId),{
                    loading: 'Loading Error Table Summary...',
                    success: (res) => {if (res.content?.length === 0) return'Error Log Empty'; else return'Fetched Error Log';},
                    error: (er: AxiosError) => `Error: ${er.response?.data}`,
                });
                let result = res.content as IVwErrorTableTaskSummary[];
                if(isMounted)
                {
                    setErrorTableTaskSummary(result);
                }

                let csvReport: Csv = {
                    data: result,
                    headers: headers,
                    filename: `Task${selectedTaskId}ErrorReportSummary.csv`
                };
                setCsv(csvReport);
                
            } catch (error) {
                const ax = error as AxiosError;
                console.table(ax.response?.data);
            }
            finally{
                setLoading(false);
            }
        }
    }

    //  ErrorTableTaskSummary update dependent on change in selectedTaskId 
    useEffect(() => {
        isMounted = true;
        getErrorTableTaskSummary();
        return () => {
            isMounted = false;
        }
    }, [selectedTaskId]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IVwErrorTableTaskSummarySortable) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const EnhancedTableHead = (props: EnhanceTableProps): JSX.Element => {
        const { order, orderBy } = props;
        const createSortHandler = (property: keyof IVwErrorTableTaskSummarySortable) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell: HeadCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };
    return (
        taskErrors.length === 0 ? <h2>Error log empty for selected task.</h2> :
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={10}>
                <Box>
                    <TableContainer>
                        <Table size="medium">
                            <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                />
                            <TableBody>
                                {
                                    taskErrors?.map((row: IVwErrorTableTaskSummary, i: number) => {
                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={i}
                                            >
                                                {
                                                    headCells.map((val: HeadCell, j: number) => {
                                                        const { id } = val;
                                                        return (
                                                            <TableCell key={j}>{row[id]}</TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </Grid>
    )
}
export default TaskErrorGrid;