import React, { useState, useEffect, useReducer, useContext, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, FormControlLabel } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "../components/Title";
import ConsultantService from "../services/Consultants";
import { IConsultant } from '../models/IConsultant'
import TenantService from "../services/Tenants";
import ITenant from "../models/ITenant";
import AssignmentService from "../services/Assignments";
import IAssignment from "../models/IAssignment";
import { IRestResponse } from "../services/RestUtilities";
import AccountCircle from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TransferList from "../components/TransferList";
import { AssignmentsContext } from "../contexts/AssignmentContext";
import { UsernameContext } from "../contexts/UsernameContext";
import ConsultantReducer from '../reducers/ConsultantsReducer';
import { LoadingContext } from '../contexts/LoadingContext';
import { ConsultantContext } from '../contexts/ConsultantContext';
import { AccountInfo } from '@azure/msal-common';
import { useMsal, useAccount } from "@azure/msal-react";
import { setApiAccessToken } from '../services/AuthService';
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import DeleteDialog from "./DeleteDialog";
import { useConsultantStyles } from "../themes/ConsultantStyles";

const setIsMounted = (isMounted: boolean) => {
  return isMounted;
}

export const Consultants = (): JSX.Element => {
  const consultantClasses = useConsultantStyles();
  // -MSAL-
  const { instance, accounts, inProgress } = useMsal();
  const account: AccountInfo | null = useAccount(accounts[0]);
  // -----
  const [consultants, setConsultants] = useState<IConsultant[]>([]);
  const [tenants, setTenants] = useState<ITenant[]>([]);
  const [assignments, setAssignments] = useState<IAssignment[]>([]);
  const [deleteDialogOn, setDeleteDialogOn] = useState(false);
  const [editDialogOn, setEditDialogOn] = useState(false);
  const [recordIdToDelete, setRecordIdToDelete] = useState<string>('');
  const [usernameToEdit, setUsernameToEdit] = useState<string>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { consultant : consultantCtx } = useContext(ConsultantContext);
  const { username } = useContext(UsernameContext);
  const isNewConsultant: boolean = usernameToEdit === "";
  const isMounted = useMemo<boolean>(() => setIsMounted(true), []); 

  const onEditDialogOpen = (username: string) => {
    setUsernameToEdit(username);
    setEditDialogOn(true);
  };

  const onDeleteDialogOpen = (username: string) => {
    setRecordIdToDelete(username);
    setDeleteDialogOn(true);
  };

  const onNewConsultant = () => {
    setUsernameToEdit(""); 
    setEditDialogOn(true);
  }

  // Create/Edit Consultant 
  const handleEdit = async (consultant: IConsultant, updatedAssignments: IAssignment[]) => {
    consultant.updatedBy = username;
    setEditDialogOn(false);
    await saveConsultant(consultant);

    // On edit, make copy, find record, update it, set new result. Updates UI
    let tableDataCopy = [...consultants];
    let editedItemIdx = tableDataCopy.findIndex(
      ({ username }: IConsultant) => username === consultant.username
    );
    tableDataCopy.splice(editedItemIdx, 1, consultant); // adds consultant if not already in array
    setConsultants(tableDataCopy);
    // if already a consultant update assignments
    if (consultant.username) {
      updateAssignments(consultant.username, updatedAssignments);
    }
  };

  const handleDelete = async() => {
    setDeleteDialogOn(false);
    if(!recordIdToDelete){
      console.log("Username to delete is empty...");
    }
    else {
      setIsLoading(true);
      try{ 
        await toast.promise(ConsultantService.delete(recordIdToDelete), {
          loading: 'Loading...',
          success: 'Processing Delete Consultant Job.',
          error: (err: AxiosError) => `Delete Consultant Unsuccessful: ${err.response?.statusText}`
        })
        const tableDataCopy = [...consultants];
        setConsultants(tableDataCopy.filter(({ username }) => username !== recordIdToDelete));
      }
      catch(error){
        const ax = error as AxiosError;
        console.table(ax.response?.data);
      }
      finally{
        setIsLoading(false);
      }
    }
  };

  const saveConsultant = async(consultant: IConsultant): Promise<void> => {
    setIsLoading(true);
    try{
      await setApiAccessToken(instance, account!);
      await toast.promise(ConsultantService.save(consultant), {
        loading: 'Processing Save Consultant...',
        success: 'Success: Consultant Saved.',
        error: (err: AxiosError) => `Save Consultant Unsuccessful: ${err.response?.statusText}`
      })
    }
    catch(error){
      const ax = error as AxiosError;
      console.table(ax.response?.data);
    }
    finally{
      setIsLoading(false);
    }
  };

  const getConsultants = async(): Promise<void> => {
    setIsLoading(true);
    try{
        setIsLoading(true);
        await setApiAccessToken(instance, account!);
        let response: IRestResponse<IConsultant[]> = await ConsultantService.fetchAll();
        let consultants = response.content as IConsultant[];
        const tableDataCopy = [...consultants];
        if(isMounted){
          setConsultants(tableDataCopy);
        }
    }
    catch(error){
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Could not get Consultants."));
      }
    }
    finally{
      setIsLoading(false);
    }
  };

  const getTenants = async(): Promise<void> => {
    try{
        await setApiAccessToken(instance, account!);
        let response: IRestResponse<ITenant[]> = await TenantService.fetchAll();
        let tenants = response.content as ITenant[];
        const tableDataCopy = [...tenants];
        if(isMounted){
          setTenants(tableDataCopy);
        }
    }
    catch(error){
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Could not get Tenants."));
      }
    }
    finally{
      setIsLoading(false);
    }
  };

  const getAssignments = async(): Promise<void> => {
    try{
        await setApiAccessToken(instance, account!);
        let response: IRestResponse<IAssignment[]> = await AssignmentService.fetchAllAsync();
        let assignments = response.content as IAssignment[];
        const tableDataCopy = [...assignments];
        if(isMounted){
          setAssignments(tableDataCopy);
        }
    }
    catch(error){
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Could not get Assignements."));
      }
    }
    finally{
      setIsLoading(false);
    }
  };

  const getAssignedTenantsByUsername = (username: string): IAssignment[] => {
    return assignments.filter((a) => a.consultant === username)
  };

  const getAvailableAssignmentsByUsername = (username: string): IAssignment[] => {
    const assigned: string[] = assignments
      .filter((a) => a.consultant === username)
      .map((a) => a.tenant);
    const availableTenants: string[] = tenants
      .filter((t) => !assigned.includes(t.name))
      .map((t) => t.name)
      .sort();
    let availableAssignments: IAssignment[] = [];
    availableTenants.forEach((tenant: string) => {
      // TODO: The IAssignment interface has had properties added to it.
      //       We're casting it to get rid of the Type error, but know that it's missing the other props...
      availableAssignments.push({ consultant: username, tenant: tenant } as IAssignment);
    });
    return availableAssignments;
  };

  const updateAssignments = async (username: string, newAssignments: IAssignment[]): Promise<void> => {
    setIsLoading(true);
    try{
      setAssignments((prevState) =>
        prevState.filter((a) => a.consultant !== username).concat(newAssignments)
      );
      await AssignmentService.updateAsync(
        username,
        newAssignments
      );
    }
    catch(error){
      const ax = error as AxiosError;
      if(ax.response?.statusText)
      {
        toast(ax.response?.statusText.concat(". Could not get Consultants."));
      }
    }
    finally{
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    setIsMounted(true);
    if (inProgress === "none" && account) {
      getConsultants();
      getTenants();
      getAssignments();
    }
    return () => {
      setIsMounted(false);
    }
  }, [inProgress, account, instance]);

  interface IDataField {
    id: number;
    title: string;
    key: string;
    type: string;
  }

  const dataFields: IDataField[] = [
    { id: 0, title: "Username", key: "username", type: "string" },
    { id: 1, title: "User Email", key: "useremail", type: "string" },
    { id: 2, title: "Current Tenant", key: "currentTenant", type: "string" },
    { id: 3, title: "Enabled", key: "enabled", type: "boolean" },
    { id: 4, title: "Created On", key: "created", type: "string" },
    { id: 5, title: "Updated On", key: "updated", type: "string" },
    { id: 6, title: "Updated By", key: "updatedBy", type: "string" },
    { id: 7, title: "Interface Admin", key: "interfaceAdmin", type: "boolean" },
  ];

  const GetEditField = (props: any) => {
    const { consultant, val, title, data, setData } = props;
    const handleEdit = (key: any, value: any) => {
      setData({ ...data, [key]: value });
    };
    if (val === "currentTenant") {
      return (
        <Box>
          {!isNewConsultant && <TransferList availableTenants={getAvailableAssignmentsByUsername(consultant.username)} />}
          <TextField
            disabled
            key={val}
            defaultValue={consultant[val]}
            label={title}
            onChange={({ target: { value } }) => handleEdit(val, value)}
            fullWidth
            className={consultantClasses.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      );
    } 
    else if (typeof consultant[val] === "boolean") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={consultant[val]}
              onChange={({ target: { checked } }) => handleEdit(val, checked)}
            />
          }
          label={title}
        />
      );
    } 
    else {
      return (
        <TextField
          // If it is a new consultant, we want to enable useremail
          // If it is editing a consultant, we want to disable useremail
          disabled={val !== "useremail" || (val === "useremail" && !isNewConsultant)}
          key={val}
          defaultValue={consultant[val]}
          label={title}
          onChange={({ target: { value } }) => handleEdit(val, value)}
          fullWidth
          className={consultantClasses.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
      );
    }
  };

  const EditDialog = ({ isOpen, onDialogClose, onSubmitEdit, consultant, fields }: any) => {
    //TODO: Improvement? -> if (!consultant)
    if (consultant === undefined) {
      consultant = {
        username: "",
        useremail: "",
        created: "",
        currentTenant: "",
        currentTenantImplementerId: "",
        currentTenantPwd: "",
        enabled: false,
        interfaceAdmin: false,
        updated: "",
        updatedBy: "",
        wwsValidationOnly: false
      } as IConsultant
    }
    // data represents the consultant's dictionary of changed values
    const [data, setData] = useState<Object>();
    // TODO Check if this works
    let assigned: IAssignment[] = getAssignedTenantsByUsername(consultant.username);
    const [newAssignments, dispatch] = useReducer(ConsultantReducer, assigned);
    return (
      <AssignmentsContext.Provider value={{ state: newAssignments, dispatch: dispatch }}>
        <Dialog
          open={isOpen}
          onClose={onDialogClose}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>{isNewConsultant ? 'New' : 'Edit'} Consultant</DialogTitle>
          <DialogContent>
            <form>
              {fields.map(({ key, title }: any) => (
                <GetEditField
                  key={key}
                  consultant={consultant}
                  title={title}
                  val={key}
                  data={data}
                  setData={setData}
                />
              ))}
            </form>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => onDialogClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmitEdit({ ...consultant, ...data }, newAssignments)}
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </AssignmentsContext.Provider>
    );
  };

  const Header = ({ columnTitles }: any) => (
    <TableHead>
      <TableRow>
        {columnTitles.map(({ title, id }: any) => (
          <TableCell key={id}>{title}</TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  interface ICellProps {
    data: any;
    index: number;
  }

  const Cell = ({ data, index }: ICellProps) => {
    if (typeof data !== "boolean") {
      return <TableCell key={index}>{data}</TableCell>;
    } else {
      return (
        <TableCell key={index}>
          <Checkbox checked={data} disabled={true} />
        </TableCell>
      );
    }
  };

  interface IRowProps {
    consultant: IConsultant;
    columns: IDataField[];
    onEditDialogOpen: (username: string) => void;
    onDeleteDialogOpen: (username: string) => void;
  }

  const Row = (props: IRowProps) => {
    const { consultant, columns, onEditDialogOpen, onDeleteDialogOpen } = props;
    return (
      <TableRow>
        {columns.map(({ key }: IDataField, i: number) => (
          <Cell key={i} data={consultant[key]} index={i}></Cell>
        ))}
        <TableCell>
          <IconButton
            onClick={() => onEditDialogOpen(consultant.username)}
            disabled={!consultantCtx?.interfaceAdmin}
          >
            <EditIcon />
          </IconButton>
          <IconButton disabled={consultant.enabled ?? true} onClick={() => onDeleteDialogOpen(consultant.username)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    !isLoading ?
      <div>
        <DeleteDialog
          isOpen={deleteDialogOn}
          onDialogClose={() => setDeleteDialogOn(false)}
          onConfirmDelete={handleDelete}
          recordId={recordIdToDelete}
        />
        <EditDialog
          isOpen={editDialogOn}
          onDialogClose={() => setEditDialogOn(false)}
          onSubmitEdit={handleEdit}
          consultant={ consultants.find(({ username }) => username === usernameToEdit) }
          fields={dataFields}
        />
        <Box className={consultantClasses.ordersMain}>
          <Title>
            Consultants
              <Button
              onClick={onNewConsultant}
              variant="contained"
              disabled={!consultantCtx?.interfaceAdmin}
              color="primary">
              New Consultant
              </Button>
          </Title>
          <TableContainer>
            <Table>
              <Header columnTitles={dataFields} />
              <TableBody>
                {consultants.map((consultant) => (
                  <Row
                    key={consultant.username}
                    consultant={consultant}
                    columns={dataFields}
                    onEditDialogOpen={onEditDialogOpen}
                    onDeleteDialogOpen={onDeleteDialogOpen}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      : <div><h1>Loading...</h1></div>
  );
};

export default Consultants;