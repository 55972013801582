import React, { useContext, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import { SelectedTenantContext } from '../contexts/SelectedTenantContext';
import { useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from '@azure/msal-common';
import Title from "./Title";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getComparator, stableSort } from '../util/Sorting';
import ITaskRegister, { ITaskRegisterSortable } from '../models/ITaskRegister';
import TaskRegisterService from "../services/TaskRegisters";
import { IRestResponse } from "../services/RestUtilities";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ITaskGridProps } from '../models/ITaskGridProps';
import { useTaskGridStyles } from '../themes/TaskGridStyles';
import { useJobButtonStyles } from '../themes/JobButtonStyles';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { Button, ButtonGroup, Checkbox, CircularProgress, TablePagination } from '@material-ui/core';
import { setApiAccessToken } from '../services/AuthService';
import EtlJobsService from '../services/EtlJobs';
import { UsernameContext } from '../contexts/UsernameContext';
import ITaskK8sEventsDto from '../dtos/ITaskK8sEventsDto';
import TaskRegisters from '../services/TaskRegisters';
import IETLJobs from '../models/IJob';
import { WdCredentialsContext } from '../contexts/WdCredentialsContext';

interface HeadCell {
    disablePadding: boolean;
    id: keyof ITaskRegisterSortable;
    label: string;
}

const headCells: HeadCell[] = [
    { id: 'taskId', disablePadding: false, label: "Task Id" },
    { id: 'name', disablePadding: false, label: "Name" },
];

interface EnhanceTableProps {
    order: Order;
    orderBy: keyof ITaskRegister;
    rowCount: number;
    numSelected: number;
    
}

export type Order = 'asc' | 'desc';

const setIsMounted = (isMounted: boolean) => {
    return isMounted;
}

const MultiTaskGrid = (props: ITaskGridProps) => {
    const { taskType, taskAbbreviation} = props;
    const classes = useTaskGridStyles();
    const jobButtonclasses = useJobButtonStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ITaskRegisterSortable>('taskId');
    const [tasks, setTasks] = useState<ITaskRegister[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const { instance, accounts, inProgress } = useMsal();
    const account: AccountInfo | null = useAccount(accounts[0]);
    const { selectedTenant } = useContext(SelectedTenantContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { isLoading } = useContext(LoadingContext);
    const { username } = useContext(UsernameContext);
    const { wdImplementerPwd } = useContext(WdCredentialsContext);
    const isMounted = useMemo<boolean>(() => setIsMounted(true), []); 
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loading, setLoading] = useState(false);

    const getTasks = async (): Promise<void> => {
        setIsLoading(true);
        let res = await toast.promise<IRestResponse<ITaskRegister[]>>(TaskRegisterService.fetchAll(taskType), {
            loading: 'Fetching Tasks...', 
            success: () => {return `Fetched ${taskType} Tasks` },
            error: (err: Error) => `Task Register Error: ${err.message}. Please see system administrator`,
        });
        let tasks = res.content as ITaskRegister[];
        if(isMounted){
            setTasks(tasks);
            }
        setIsLoading(false);
    }

    const handleCheckBoxClick = (taskId: string): void => {
        
        // If the user clicks the same entry again, deselect it.
        const selectedIndex = selected.indexOf(taskId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, taskId);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    const handleExtractOnClick = async (): Promise<void> => {
        
        if (!loading) {
          try {
            setLoading(true);
            await setApiAccessToken(instance, account!);
            let selectedTasks = getSelectedTasks();
            
            const job: IETLJobs = {
                TaskRegisters: selectedTasks,
                Tenant: selectedTenant,
                Consultant: username,
                Password: wdImplementerPwd,
                JobType: "E",
            }
            let res = await toast.promise<IRestResponse<ITaskRegister[]>>(EtlJobsService.extractMultiple(job), {
                success: 'Success: Jobs submitted.',
                error: (err: AxiosError) => `Error: ${err.response?.data}`,
                loading: 'Loading...'
            });

            const executedJobs = res.content as ITaskRegister[];
            executedJobs.length > 0 ? updateMultipleJobStatus(executedJobs, "E") : toast.error("No Jobs Executed");
          }
          catch (error) {
            const ax = error as AxiosError;
            console.table(ax.response?.data);
          }
          finally {
            setLoading(false);
          }
        }
    }

    const getSelectedTasks = (): ITaskRegister[]  => {
        
        let selectedItems: ITaskRegister [] = [];
        selected.forEach(element => {
            let filtered = tasks.filter((task, index, arr) => { 
                return task.taskId === element;
            });
            selectedItems.push(filtered[0]);
        });

        return selectedItems;
    }

    const updateMultipleJobStatus = async (tasks: ITaskRegister[], jobType: string): Promise<void> => {
        try {
          setLoading(true);
          const taskK8sEventDto: ITaskK8sEventsDto = {
              Tasks: tasks,
              FunctionalArea: taskType,
              TenantName: selectedTenant,
              JobType: jobType
          } 
          await toast.promise<IRestResponse<ITaskRegister>>(TaskRegisters.updateMultipleTaskK8sEvents(taskK8sEventDto), {
            success: 'Updated Job Status.',
            error: (err: AxiosError) => `Status Update Error: ${err.response?.data}`,
            loading: 'Processing Status Change...'
          }, { 'duration': 10 } );
        } 
        catch (error) {
          const ax = error as AxiosError;
          if(ax.response?.statusText)
          {
            toast(ax.response?.statusText.concat(". Update Status Unsuccessful."));
          }
          console.table(ax.response?.data);
        }
        finally{
            setIsLoading(false);
        }
      }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ITaskRegisterSortable) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = tasks.map((task) => task.taskId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    useEffect(() => {
        if (inProgress === "none" && account && selectedTenant) {
            getTasks();
        }
        return () => {
            setIsMounted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, account, selectedTenant, taskType]);

    const EnhancedTableHead = (props: EnhanceTableProps): JSX.Element => {
        const { order, orderBy, numSelected, rowCount } = props;
        const createSortHandler = (property: keyof ITaskRegisterSortable) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={handleSelectAll}
                        inputProps={{ 'aria-label': 'Select All ' }}
                    />
                    </TableCell>
                    {headCells.map((headCell: HeadCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        isLoading ? <div><h1>Loading...</h1></div>
        :<Grid container direction={'row'}>
                <Grid item xs={12}>
                    <Box className={classes.tasksMain}>
                        <Title>{taskAbbreviation} Tasks</Title>
                        <ButtonGroup  variant="text" className={jobButtonclasses.buttons} color="primary" aria-label="outlined primary button group">
                            <Button
                            style={{ minWidth: "100px" }}
                            onClick={() => handleExtractOnClick()}
                            disabled={selected.length > 0 ? false : true}
                            >Extract
                            {loading && <CircularProgress size={24} className={jobButtonclasses.buttonProgress} />}
                            </Button>
                        </ButtonGroup>
                        <TableContainer className={classes.tasksContainer}>
                            <Table stickyHeader={true} aria-label="sticky table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    rowCount={tasks.length}
                                    numSelected={selected.length}
                                />
                                <TableBody>
                                    {Array.isArray(tasks) ? stableSort<ITaskRegister>(tasks, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((task: ITaskRegister, i: number) => {
                                                const isItemSelected = isSelected(task.taskId);
                                                const labelId = `enhanced-table-checkbox-${i}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={() => handleCheckBoxClick(task.taskId)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={task.name}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                        {
                                                            headCells.map((val: HeadCell, i: number) => {
                                                                const { id } = val;
                                                                return (
                                                                    <TableCell key={i}>{task[id]}</TableCell>
                                                                )
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                            : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 40, { label: 'All', value: tasks.length }]}
                            count={tasks.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            component="div"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid> 
            </Grid>
    )
}

export default MultiTaskGrid;